import React from 'react';
import Modal from 'react-bootstrap/Modal'
import './PopupImage.css'

function PopupImage(props) {
    return (
        <div style={{background: "black"}}>
            <Modal {...props}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <button onClick={props.onHide} className="close-btn mr-1 mt-1"><i className="fas fa-times fa-1x"/>
                </button>
                <img className="modal-img" src={props.imgsrc} alt=""/>
            </Modal>
        </div>
    );
}

export default PopupImage;
