import React, {Component} from 'react';
import PopupImage from '../../PopupImage/PopupImage'
import './Exhibition.css';
import {LazyLoadImage} from "react-lazy-load-image-component";

let listOfCompressedImages = [];
let listOfHighResolutionImages = [];

class ExhibitionGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showImageViewer: false,
            popupImageSrc: null
        };

        listOfCompressedImages = ExhibitionGallery.importAll(require.context('../../../../public/images/izlozba/compressed', false, /\.(png|jpe?g|svg|JPG)$/));
        listOfHighResolutionImages = ExhibitionGallery.importAll(require.context('../../../../public/images/izlozba/hd', false, /\.(png|jpe?g|svg|JPG)$/));
    }

    static importAll(r) {
        return r.keys().map(r);
    }

    showImageViewer(index) {
        this.setState({
            showImageViewer: !this.state.showImageViewer,
            popupImageSrc: listOfHighResolutionImages[index]
        })
    };

    hideImageViewer() {
        this.setState({
            showImageViewer: !this.state.showImageViewer,
            popupImageSrc: null
        });
    };

    render() {
        return (
            <div className="container">
                <h2 className="mb-3 mt-4 font-italic">Boris papandopulo i Tribunj</h2>
                <div className="row mb-2">
                    {listOfCompressedImages.map((image, index) =>
                        <div className="col-lg-6 mb-2" key={index}>
                            <div className="image-box-exhibition rounded">
                                <LazyLoadImage effect="opacity" src={image} alt=""
                                               onClick={() => this.showImageViewer(index)}/>
                            </div>
                        </div>
                    )}
                </div>
                {this.state.popupImageSrc ?
                    <PopupImage
                        imgsrc={this.state.popupImageSrc}
                        show={this.state.showImageViewer}
                        onHide={() => this.hideImageViewer()}
                    />
                    : null
                }
            </div>
        );
    }
}

export default ExhibitionGallery;
