import React, {Component} from 'react';
import './Festival.css'
import {LazyLoadImage} from 'react-lazy-load-image-component';


class Festival extends Component {
    render() {
        return (
            <div className="container page">

                <div className="row mt-5">
                    <div className="col-lg-4">
                        <LazyLoadImage className="img-fluid rounded b-img"
                                       src='/images/papandopulo/papandopulo_sharp.jpg'
                                       alt="papandopulo"
                                       effect="opacity"/>
                        <p className="text-center font-italic mt-1">Boris Papandopulo</p>
                    </div>
                    <div className="col-xl-8">
                        <h2>O festivalu</h2>
                        <p className="text-justify">
                            Papandopulijana Tribunj je festival glazbenih, glazbeno – scenskih, plesnih i filmskih
                            programskih sadržaja, projekt kojim Općina Tribunj promiče kulturni identitet u turizmu
                            imenom
                            prominentnog hrvatskog skladatelja i dirigenta maestra Borisa Papandopula. Ovaj hrvatski
                            akademik, umjetnik iznimne glazbene nadarenosti, prepoznat je u cijeloj Europi a u Tribunju
                            je
                            djelovao u zadnjem periodu svojeg života od 1974. do 1991. godine. Papandopulijana Tribunj
                            ima
                            za cilj povezati glazbenu, plesnu, dramsku i filmsku komponentu skladateljeva opusa.
                            Programska
                            raznolikost, širina i bogatstvo klasičnih glazbenih tema prožetih nacionalnim folklorom i
                            nezaobilaznim skercoznim prepoznatljivim papandopulijanskim motivima, upućenost na balet i
                            scenski pokret, film i pučko pjevanje u maestrovom opusu temelj su i garancija svih posjeta
                            festivalu od strane domicilnog stanovništva Tribunja, srednje Dalmacije, Republike Hrvatske
                            i
                            sve većeg broja turista dolaznika iz cijeloga svijeta.</p>
                        <p className="text-justify">
                            Glazbeni opus Borisa Papandopula broji 490 djela simfonijskog,
                            opernog, baletnog , plesno - scenskog, zborskog i komornog repertoara kao i glazbu za
                            dramske predstave, filmove i skladbe pučkog karaktera.</p>
                        <p className="text-justify">
                            Od samog ostvarenja prvog festivala 2013. prepoznat je ekskluzivan
                            turistički proizvod – festivalski program zaštitne marke koji stvara preduvjete Općini
                            Tribunj, autohtonom biseru s već postojećim tradicijskim sadržajima, na dobivanju vrijedne
                            statusne dimenzije na karti Jadrana - dimenziji kulturne prepoznatljivosti.</p>
                    </div>
                    <div className="col">
                        <p className="text-justify">
                            Svemu ovom pogoduje bliskost gradova Šibenika, Vodica, Skradina i općina Tisno i
                            Murter-Kornati s ljetnom publikom koja u spomenutim gradovima i općinama nema priliku
                            posjetiti festivalske programe ovakvog profila. Tribunj je pozicioniran na pola puta između
                            dva najveća dalmatinska središta, gradova Splita i Zadra. Sa svim spomenutim gradovima i
                            općinama želja nam je ostvarivati suradnju razmjenom umjetnika i festivalskih programa
                            (Splitsko ljeto, Glazbene večeri u Sv. Donatu, Šibensko kulturno ljeto). Dodatna mogućnost
                            jest kulturološka poveznica s gradovima Rostockom, Baselom, Firenzom, Sarajevom, Beogradom,
                            Skopljem, Kairom čiji su umjetnici izvodili i naručivali skladbe od maestra Borisa
                            Papandopula. Ulaskom Hrvatske u Europsku uniju otvorila se mogućnost suradnje sa
                            institucijama i umjetnicima spomenutih gradova na izradi zajedničkih programa i projekata u
                            kulturi, obrazovanju mladih umjetnika i turizmu. </p>
                        <p className="text-right">prof. Marijan Modrušan</p>
                    </div>
                </div>
                <hr/>
                <div className="row mt-5">
                    <div className="col-lg-7">
                        <h2>Boris Papandopulo</h2>
                        <p className="text-justify pt-2">
                            Boris Papandopulo (Honnef am Rhein [od 1960. Bad Honnef], Njemačka, 25. veljače 1906. –
                            Zagreb, 16. listopada 1991.) jedan je od najmarkantnijih hrvatskih glazbenika XX. stoljeća.
                            »Rođen, odrastao i odgojen u obitelji koja je oduvijek bila tijesno povezana s muzikom i
                            kazalištem«, glazbi se posvećuje vrlo rano. Papandopulo najprije privatno uči glasovir,
                            zatim studira kompoziciju na Muzičkoj akademiji u Zagrebu (gdje je slušao predavanja F.
                            Dugana, F. Lhotke, A. Dobronića, a kompoziciju učio i diplomirao u klasi B. Berse 1929.), a
                            potom u Beču, na Novom bečkom konzervatoriju, dirigiranje kod Dirka Focka (1928. – 1930.). U
                            dva navrata (1928. – 1934. i 1938. – 1946.) dirigent je Hrvatskog pjevačkog društva Kolo u
                            Zagrebu, a od 1931. do 1934. dirigent Društvenog orkestra Hrvatskog glazbenog zavoda kao i
                            zborovođa Učiteljskog pjevačkog društva Ivan Filipović (koje je i utemeljio 1933.). Od 1935.
                            do 1938. radi kao profesor na Državnoj muzičkoj školi u Splitu i djeluje kao dirigent
                            Glazbenog društva Zvonimir, od 1940. do 1945. Zagrebačke opere (od toga 1943. – 1945. i kao
                            ravnatelj), a istovremeno je i dirigent (orkestra) Zagrebačke radio stanice (1942. – 1945.).
                            Poslije Drugog svjetskog rata ravnatelj je Opere u Rijeci (1946. – 1948. i 1953. – 1959.), a
                            od 1948. do 1953. djeluje kao operni dirigent i profesor u Sarajevu. Karijeru nastavlja u
                            Zagrebu ponovno kao dirigent Zagrebačke opere (1959. – 1968.), a zatim i Splitske (1968. –
                            1974.). Bio je i stalni gost dirigent kazališta Komedija u Zagrebu kao i Simfonijskog
                            orkestra u Kairu.</p>
                    </div>
                    <div className="col-lg-5 mt-5 text-center pt-5">
                        <LazyLoadImage effect="opacity" className="img-fluid rounded"
                                       src='/images/papandopulo/papandopulo_zdenka.jpg'
                                       alt="papandopulo"/>
                        <p className="font-italic mt-1">Boris i Zdenka Papandopulo</p>
                    </div>
                    <div className="col-lg-12">
                        <p className="text-justify">
                            Papandopulo djeluje i kao glazbeni pisac, publicist, kritičar, pijanist i korepetitor, a
                            vrhunce glazbeničkog djelovanja dosegao je kao skladatelj. Papandopulov skladateljski opus
                            je impozantan: s velikim uspjehom stvarao je instrumentalnu (orkestralnu, koncertantnu,
                            komornu, solističku), vokalno-instrumentalnu (solističku i zborsku), glazbeno-scensku i
                            filmsku glazbu. U svim tim glazbenim vrstama i žanrovima ostavio je niz antologijskih
                            skladbi velike umjetničke vrijednosti.
                        </p>
                        <p className="text-justify">
                            Papandopulove mladenačke opuse obilježava uporaba značajki tzv.»nacionalnog glazbenog
                            stila«, folklornih glazbenih uzoraka (direktnih citata, ali i folklora kao sirove građe i
                            modalnih ljestvičnih struktura), a zamjetni su i »kozmopolitski« utjecaji: primjena
                            skladateljsko-tehničkih elemenata neoklasičnog stila: polifonijske fakture, barokne
                            motoričnosti i živog ritamskog pokreta s »dodirima« elemenata
                            impresionističkog i ekspresionističkog glazbenog idioma. Uz virtouzno, znalačko i tehnički
                            zahtjevno tretiranje instrumenata zamjetan je optimizam i vedrina koja tu glazbu gotovo u
                            potpunosti prožima. Dobri poznavatelji skladateljevog opusa iz ranijeg (mladenačkog)
                            stvaralačkog razdoblja kao najuspješnija djela izdvajaju Laudamus (Slavoslovije), kantatu za
                            sole, mješoviti zbor i orkestar, Simfonijetu za gudački orkestar (izdao Breitkopf und
                            Härtel), Zlato, pantomimu balet s pjevanjem i orkestrom, briljantan i bravurozan Concerto da
                            camera za sopran solo, violinu i sedam duhačkih instrumenata (izdao bečki Universal Edition)
                            te najznačajnija hrvatska sakralna djela s kraja tog Papandopulovog kreativnog razdoblja –
                            oratorij za sole i muški zbor a cappella Muka Gospodina našeg Isukrsta i Hrvatska misa za
                            soliste i mješoviti zbor a cappella.
                        </p>
                        <p className="text-justify">
                            U svom »zrelom« stvaralačkom razdoblju Papandopulo zadržava elemente folklornog idioma, ali
                            se okreće dostignućima europske glazbene Moderne, a da se »pritom ne odvaja od
                            tradicionalnih formanata glazbenih ćelija, od uvriježena motivičko-fakturalnog razvoja ili
                            uhodanih zakona melodijskog kretanja«. Ovo razdoblje, kroz koje prolazi vremenska os
                            završetka Drugog svjetskog rata (1945.), traje sve do otprilike 1956. i u njemu nastaje niz
                            vrlo uspjelih skladbi koje su obilježene recentnom poviješću – stvaranjem nove države i
                            događajima iz tzv. Narodno-oslobodilačke borbe (Simfonija br. 2, Poema o Neretvi, Stojanka
                            majka Knežopoljka, muzička poema za sopran solo, zbor i veliki orkestar, Obnova, simfonijski
                            pokret za veliki orkestar itd.)
                        </p>
                        <p className="text-justify">
                            S vremenom Papandopulova glazba postaje disonantnija, harmonijski i melodijski oporija.
                            Sredinom 50-tih godina 20. stoljeća on u svoj skladateljsko-tehnički arsenal integrira i
                            elemente dvanaesttonske tehnike, jazza (intenzivnije krajem 50-tih i početkom 60-tih godina;
                            Koncert za klavir i orkestar br. 3, Mozaik za klasični gudački kvartet i jazz kvartet,
                            Capriccio za violinu-solo i jazz-kvartet, Ein Orchestermosaik, itd.), kasnije i pop-glazbe,
                            ali i drugih skladateljsko-tehničkih postupaka avangardne glazbe 20. stoljeća, iako spram
                            nekih od njih zadržava kritičku distancu, a ponekad ih čak ironizira ili parodira.
                        </p>
                        <p className="text-justify">
                            Nastanak dijela Papandopulovog opusa 60-tih i 70-tih godina vezan je i uz njegova gostovanja
                            i poznanstva s glazbenicima u tadašnjoj podijeljenoj Njemačkoj (BRD i DDR), gdje se imao
                            prilike susresti s iznimnim umjetničkim osobnostima, ali i recentnim europskim glazbenim
                            stvaralaštvom.
                        </p>
                        <p className="text-justify">
                            Ideološko estetski nazori Papandopula nikad nisu sputavali u odabiru izvanglazbenih sižea za
                            vlastita djela: on je uvijek u svakom od njih pronalazio univerzalne ideje, duboko
                            humanističke vrijednosti. Tako Papandopulo u početku svog stvaralaštva izvanglazbene
                            poticaje nalazi u temama povezanima sa starijim slojevima folklorne tradicije – »u
                            legendama, obredima i mitovima« (Svatovske, Dodolice, Zlato, Utva zlatokrila, itd.).
                            Kasnije, nakon 1950., on, gotovo kuriozno, inspiraciju crpi iz naoko dijametralno suprotnih
                            svjetonazora: istovremeno piše skladbe religiozne tematike – specifično hrvatskog katoličkog
                            bogoslužja s tragovima i elementima glagoljaškog pjevanja (Osorski rekvijem, Pučka poljička
                            misa, kantata Jubilate, niz manjih sakralnih djela) s monumentalnim djelima socijalističkog
                            realizma (nadahnutih ideologijom i poetikom poslijeratne socijalističke revolucije i NOB-om,
                            kao što su glazbeno poetsko djelo Pasija po srcu, poetsko-glazbena vizija Credo, 1943. –
                            ciklus pjesama na tekstove I. G. Kovačića), ali i ona inspirirana hrvatskom poviješću
                            (kantate Libertas, Pohvala Dubrovniku, Non bene pro toto libertas venditur auro, Mile
                            Gojslavica, triptihon za mješoviti zbor i veliki simfonijski orkestar Istarske freske,
                            itd.).
                        </p>
                        <p className="text-justify">
                            Papandopulo je intenzivno i nadahnuto skladao gotovo do dana smrti, punih 65 godina. U svim
                            tim djelima on se potvrđuje kao majstor svog metijea – duhoviti glazbenik neiscrpne i svježe
                            inspiracije, besprijekorni poznavatelj skladateljsko-tehničkih postupaka, glazbenih formi te
                            mogućnosti instrumenata i vokalne tehnike.
                        </p>
                        <p className="text-justify">
                            Osim niza koncerata i solističkih djela za pojedine instrumente, Papandopulo je markantno
                            obilježio baletnu i opernu produkciju svog vremena: njegovih 6 opera (Amfitrion, Rona,
                            Sunčanica, Madame Buffault, Kentervilski duh, Požar u operi) i 15 baleta (Žetva, Tri
                            kavalira gospođice Melanije, Teuta, Horoskop (baletna suita za dva glasovira), Veze (baletne
                            skice za komorni ansambl), Zlato (pantomima balet s pjevanjem i orkestrom), Kći dalmatinskih
                            planina, Intermezzo, Beatrice Cenci, Gitanella, „Dr. Atom“, Kraljevo, Prsten, Menschen im
                            Hotel, Der Königsreiher) svoju antologijsku vrijednost dokazuju brojnim opetovanim izvedbama
                            na pozornicama širom Hrvatske i svijeta. Skladao je i glazbu za pozamašan broj kazališnih
                            predstava i filmova, a neke od svojih opusa posvetio je i djeci i mladima (lutkarske
                            predstave, instrumentalne skladbe i instrumentalne koncerte).
                        </p>
                        <p className="text-justify">
                            Velik broj Papandopulovih djela odlikuje originalan papandopulovski prštav, zarazni glazbeni
                            humor (u velikom rasponu od igre tonovima punom vedrine pa sve do persiflaže i opore
                            glazbene groteske) u kojem često citira i teme drugih autora (Groteska
                            za tubu, glasovir i udaraljke, Pop koncert za dva klavira i orkestar, Čarobna frula,
                            Papandopulijada, Divertimento alla pasticcio, itd.)
                        </p>
                        <p className="text-justify">
                            Sažetu, jezgrovitu i sveobuhvatnu ocjenu Papandopulovog ogromnog opusa iz današnje
                            perspektive, zbog nemogućnosti pristupa cjelokupnoj notnoj ostavštini nije moguće dati.
                            Ipak, skladatelj Dubravko Detoni pokušao je to učiniti sljedećim riječima: »Ako bi pak
                            trebalo izreći nekakvu definiciju, onda bi sažetak Papandopulova opusa
                            valjalo potražiti u nekoj vrsti sinteze svih važnijih novih glazbeno-svjetskih utjecaja s
                            ritmičko-melodijsko-harmonijskim značajkama hrvatskog narodnog melosa.
                        </p>
                        <p className="text-justify">
                            Bez obzira na vrijeme nastanka ili stilsku i glazbenu izražajnu usmjerenost Papandopulova
                            »glazba vrlo lako i spontano pronalazi neposredan dodir sa slušateljstvom«, a glazbenici je
                            vrlo rado sviraju.
                        </p>
                        <p className="text-justify">
                            Boris Papandopulo veliku ulogu odigrao je i kao obrađivač narodnih napjeva i popijevaka iz
                            šire regije (jugoistočne Europe i Balkana) te kao popularizator i obrađivač djela drugih
                            autora, što se posebno odnosi na antologijske skladbe hrvatske koncertantne i operne glazbe,
                            od kojih je nekolicinu poput djela Vatroslava Lisinskog, Ivana pl. Zajca i Franje Krežme
                            revitalizirao i u vlastitoj obradi sam postavio na scenu.
                        </p>
                        <p className="text-right">dr. sc. Davor Merkaš</p>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

export default Festival;