import React from 'react';
import './Navbar.css';
import {Link} from 'react-router-dom';


function Navbar() {
    return (
        <nav className="navbar fixed-top navbar-expand-lg nav-colors navbar-dark">
            <div className="container">
                <a className="navbar-brand" href="/"><img src={'/images/logo/logo.png'} alt="logo"
                                                          className="img-fluid logo_picture"/></a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                        data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className="nav-link" to="/">Naslovnica</Link>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className="nav-link" to="/festival">Festival</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownPortfolio"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Program
                            </a>
                            <div className="dropdown-menu dropdown-menu-right"
                                 aria-labelledby="navbarDropdownPortfolio">
                                <Link className="dropdown-item" to="/program/2024">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2024
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2023">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2023
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2022">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2022
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2021">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2021
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2020">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2020
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2019">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2019
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2018">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2018
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2017">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2017
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2016">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2016
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2015">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2015
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2014">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2014
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/program/2013">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2013
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownPortfolio"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Fotografije
                            </a>
                            <div className="dropdown-menu dropdown-menu-right"
                                 aria-labelledby="navbarDropdownPortfolio">
                                <Link className="dropdown-item" to="/multimedia/2023">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2023
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2022">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2022
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2021">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2021
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2019">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2019
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2018">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2018
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2017">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2017
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2016">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2016
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2015">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2015
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2014">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2014
                                    </div>
                                </Link>
                                <Link className="dropdown-item" to="/multimedia/2013">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Papandopulijana
                                        2013
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownPortfolio"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Izložba
                            </a>
                            <div className="dropdown-menu dropdown-menu-right"
                                 aria-labelledby="navbarDropdownPortfolio">
                                <Link className="dropdown-item" to="/exhibition/info">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">O Izložbi</div>
                                </Link>
                                <Link className="dropdown-item" to="/exhibition/gallery">
                                    <div data-toggle="collapse" data-target=".navbar-collapse.show">Galerija</div>
                                </Link>
                            </div>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className="nav-link" to="/location">Lokacija</Link>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <Link className="nav-link" to="/contact">Kontakt</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
