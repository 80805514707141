import React, {Component} from 'react';
import './App.css';
// Layout components
import Navbar from './components/Layout/Navbar/Navbar';
import Footer from './components/Layout/Footer/Footer';
// React Router Import
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
// Import Pages
import Homepage from './components/Pages/Homepage/Homepage';
import ExhibitionGallery from './components/Pages/Exhibition/ExhibitionGallery';
import ExhibitionInfo from './components/Pages/Exhibition/ExhibitionInfo';
import Contacts from './components/Pages/Contact/Contacts';
import Festival from './components/Pages/Festival/Festival';
import Location from './components/Pages/Location/Location';


import Photos2022 from './components/Pages/Photos/Photos2022';
import Photos2021 from './components/Pages/Photos/Photos2021';
import Photos2019 from './components/Pages/Photos/Photos2019';
import Photos2018 from './components/Pages/Photos/Photos2018';
import Photos2017 from './components/Pages/Photos/Photos2017';
import Photos2016 from './components/Pages/Photos/Photos2016';
import Photos2015 from './components/Pages/Photos/Photos2015';
import Photos2014 from './components/Pages/Photos/Photos2014';
import Photos2013 from './components/Pages/Photos/Photos2013';
import Program from './components/Pages/Program/Program';

import NotFound from './components/Pages/NotFoundPage/NotFoundPage';
import Photos2023 from "./components/Pages/Photos/Photos2023";


class App extends Component {
    render() {
        return (
            <Router>
                <Navbar/>
                <div className="spacer"/>
                <Switch>
                    <Route exact path="/" component={Homepage}/>
                    <Route exact path="/exhibition/gallery" component={ExhibitionGallery}/>
                    <Route exact path="/exhibition/info" component={ExhibitionInfo}/>
                    <Route exact path="/contact" component={Contacts}/>
                    <Route exact path="/festival" component={Festival}/>
                    <Route exact path="/location" component={Location}/>

                    <Route exact path="/multimedia/2023" component={Photos2023}/>
                    <Route exact path="/multimedia/2022" component={Photos2022}/>
                    <Route exact path="/multimedia/2021" component={Photos2021}/>
                    <Route exact path="/multimedia/2019" component={Photos2019}/>
                    <Route exact path="/multimedia/2018" component={Photos2018}/>
                    <Route exact path="/multimedia/2017" component={Photos2017}/>
                    <Route exact path="/multimedia/2016" component={Photos2016}/>
                    <Route exact path="/multimedia/2015" component={Photos2015}/>
                    <Route exact path="/multimedia/2014" component={Photos2014}/>
                    <Route exact path="/multimedia/2013" component={Photos2013}/>


                    <Route exact path="/program/2024" component={Program}/>
                    <Route exact path="/program/2023" component={Program}/>
                    <Route exact path="/program/2022" component={Program}/>
                    <Route exact path="/program/2021" component={Program}/>
                    <Route exact path="/program/2020" component={Program}/>
                    <Route exact path="/program/2019" component={Program}/>
                    <Route exact path="/program/2018" component={Program}/>
                    <Route exact path="/program/2017" component={Program}/>
                    <Route exact path="/program/2016" component={Program}/>
                    <Route exact path="/program/2015" component={Program}/>
                    <Route exact path="/program/2014" component={Program}/>
                    <Route exact path="/program/2013" component={Program}/>
                    {/*
                    <Route exact path="/seminar-marimba" component={Seminar}/>
                    */}


                    <Route path='*' exact={true} component={NotFound}/>
                </Switch>
                <Footer/>
            </Router>
        );
    }
}

export default App;
