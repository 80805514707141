export const organisers = [
    {
        'name': 'Marko Grubelić',
        'role': 'Načelnik Općine Tribunj',
        "img": '/images/clanovi/marko_grubelic.jpg',
        'email': 'marko.grubelic@tribunj.hr',
        'tel': '99 585 4361 KRIVO!',
    },
    {
        'name': 'Marijan Modrušan',
        'role': 'Umjetnički voditelj',
        "img": '/images/clanovi/marijan_modrusan.png',
        'email': 'marijan.modrusan@hotmail.com',
        'tel': '99 585 4361',
    },
    {
        'name': 'Josipa Jerkov',
        'role': 'Izvršna direktorica',
        "img": '/images/clanovi/josipa_jerkov.jpg',
        'email': 'josipa.jerkov@tribunj.hr',
        'tel': '91 479 1440',
    },
];

