import React from 'react'
import './Footer.css'

let sponsors_1;
let sponsors_2;

class Footer extends React.Component {
    render() {
        return (
            <div className="main-footer">
                <div className="container">
                    <div className="row text-center">
                        <div className="container mr-4 ml-4">
                            <div className="row justify-content-center">
                                <a className="m-3" target="_blank" rel="noopener noreferrer"
                                   href="https://www.facebook.com/Papandopulijana-Tribunj-212582592235119/"><i
                                    className="fab fa-facebook-f m-3"/></a>
                                <a className="m-3" target="_blank" rel="noopener noreferrer"
                                   href="https://www.youtube.com/channel/UCYdEdqSukuUG728htiC40tQ"><i
                                    className="fab fa-youtube m-3"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="pt-3 pb-1 text-center footer-bottom">
                        <p className="text-center small-text">
                            &copy; {new Date().getFullYear()} papandopulijana-tribunj.com | Created By
                            <a target="_blank" rel="noopener noreferrer"
                               href="https://www.linkedin.com/in/ivan-modru%C5%A1an-43453319a/"> Ivan Modrušan</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;