import React, {Component} from "react";
import {Link} from "react-router-dom";
import MyCarousel from "../../MyCarousel/MyCarousel";
import "./Homepage.css";
import {festivals} from "./HomepageData";


class Homepage extends Component {
    render() {
        document.querySelector("body").scrollTo(0, 0)
        const festival_cards = festivals.slice(1).map(festival => {
            return (
                <div className="col-lg-4 col-sm-6 mb-5" key={festival.year}>
                    <Link to={`/program/` + festival.year}
                          className="card shadow h-100 text-reset text-decoration-none">
                        <img className="img-fluid rounded b-img"
                             style={{height: "230px", objectFit: 'cover', width: "100%"}}
                             src={festival.images[0]}
                             alt="Festival cover image"/>
                        <div className="card-body">
                            <h5 className="card-title" style={{color: "blue"}}>
                                Papandopulijana {festival.year}
                            </h5>
                            <div className="card-text">
                                <ul>
                                    {festival.important_artists.map(artist =>
                                        <li key={artist}>{artist}</li>
                                    )}
                                </ul>
                            </div>
                            <div/>
                        </div>
                    </Link>
                </div>
            );
        });

        return (
            <div className="page">

                <div className="jumbotron jumbotron-fluid homepage-jumbotron mb-0">
                    <div className="container">
                        <h1 className="display-5">Papandopulijana</h1>
                        <p className="lead ml-1">Festival posvećen skladatelju i dirigentu Borisu Papandopulu</p>
                    </div>
                </div>

                <div className="jumbotron jumbotron-fluid bg-white mb-0">
                    <div className="container">
                        <div className="row mb-2">
                            <div className="col-lg-6 mb-3">
                                <h4 className="mb-4">
                                    <Link
                                        to={`/program/${festivals[0].year}`}>Papandopulijana {festivals[0].year}</Link>
                                </h4>

                                <strong>13.08.2024: Koncert otvorenja festivala</strong>
                                <ul style={{marginLeft: "-20px"}}>
                                    <li className="m-2">Početak u 21:00h na <Link to={`/location`}>Trgu škver</Link>
                                    </li>
                                    <li className="m-2">Kalamos kvintet</li>
                                    <li className="m-2">Glazbeno-scenski prikaz tribunjske legende "Kako je Ćušo spasio
                                        Tribunj"
                                    </li>
                                </ul>
                                <strong>14.08.2024: Papandopulijana mladih umjetnika</strong>
                                <ul style={{marginLeft: "-20px"}}>
                                    <li className="m-2">Početak u 21:00h u <Link to={`/location`}>Kulturnom centru
                                        Tribunj</Link></li>
                                    <li className="m-2">Tomislav Damjanović, klavir i Martin Krpan, violina</li>
                                </ul>
                                <strong>15.08.2024: Trio Evocación u Tribunju</strong>
                                <ul style={{marginLeft: "-20px"}}>
                                    <li className="m-2">Početak u 21:00h u <Link to={`/location`}>Crkvi Velike
                                        Gospe</Link></li>
                                    <li className="m-2">Trio Evocación: Ivan Šimatović, Lovro Peretić, Luka Lovreković
                                    </li>
                                </ul>
                                <div>
                                    Za više informacija možete pregledati <Link
                                    to={`/program/${festivals[0].year}`}>događaje</Link> na Papandopulijani {festivals[0].year}.
                                </div>
                            </div>

                            <div className="col-md-6 m-auto">
                                <MyCarousel images={festivals[0].images} controls={true} indicators={false}
                                            interval={6000}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="jumbotron jumbotron-fluid bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-5 col-12">
                                <img style={{width: "100%", maxHeight: "250px", objectFit: "cover"}}
                                     className="rounded"
                                     src="/images/galerija/2019/galerija_2019 (42).jpg"
                                     alt="zdenka_papandopulo"/>
                            </div>
                            <div className="col-sm-7 col-12 m-auto py-4">
                                <p>
                                    Zdenka Papandopulo za HRT “Vijesti iz kulture” (Tribunj, 06.08.2013.)
                                </p>
                                <p className="font-italic">
                                    “Rekao mi je Boris u svom šaljivom stilu; Zdenkica, budemo si kupili magarca
                                    pa
                                    ćemo se s njim voziti po Tribunju“
                                </p>
                                <p>
                                    Tebi <strong>in memoriam</strong> Draga Zdenka, veliko <strong>hvala</strong> na
                                    daru koji si ostavila
                                    žiteljima
                                    Tribunja i predanosti u osnivanju festivala koji s ponosom pronosi prezime
                                    Papandopulo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row mb-3">
                        {festival_cards}
                    </div>
                </div>
            </div>
        );
    }
}

export default Homepage;
