import React from 'react';
import {programs} from "./ProgramsData";
import './Program.css';
import {Link} from 'react-router-dom';

class Program extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "",
            year: "",
            displayedFestivals: []
        };
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    componentDidMount() {
        document.querySelector("body").scrollTo(0,0)
        this.setState({
            language: "hr",
            year: window.location.href.split(/[/ ]+/).pop(),
            displayedFestivals: programs[window.location.href.split(/[/ ]+/).pop()].filter(program => {
                return program.language == null;
            }),
        });

        this.unlisten = this.props.history.listen(() => {
            if (window.location.href.includes("program")) {
                this.setState({
                    language: "hr",
                    year: window.location.href.split(/[/ ]+/).pop(),
                    displayedFestivals: programs[window.location.href.split(/[/ ]+/).pop()].filter(program => {
                        return program.language == null;
                    }),
                })
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    changeLanguage() {
        document.querySelector("body").scrollTo(0,0)
        if (this.state.language === "hr") {
            this.setState({
                language: "en",
                displayedFestivals: programs[this.state.year].filter(program => {
                    return program.language === "en";
                })
            });
        } else {
            this.setState({
                language: "hr",
                displayedFestivals: programs[this.state.year].filter(program => {
                    return program.language == null;
                })
            });
        }
    }

    render() {
        return (
            <div className="container page">
                <div className="button-header mt-3">
                    <button type="button" className="btn btn-info btn-sm float-right"
                            onClick={this.changeLanguage}>{this.state.language === "hr" ?
                        <div>English</div> :
                        <div>Hrvatski</div>}</button>
                </div>
                <h2 className="mb-3 mt-4 ml-1 mr-1">
                    {this.state.language === "hr" ? "Događaji na Papandopulijani" : "Papandopulijana events"} {this.state.year}
                </h2>

                {this.state.displayedFestivals.map((event) =>
                    <div key={event.date + event.starting_time}
                         className="border pl-3 pr-3 pt-1 mb-4 rounded custom-table">
                        <div className="row p-2">
                            <div className="w-50 p-1">
                                <h4 className="align-middle">{event.title}</h4>
                            </div>
                            <div className="w-50 text-right">
                                <h5 className="pt-1">{event.date} &nbsp;{event.starting_time}h,</h5>
                                <h5>{event.location} <Link to="/location"><i
                                    className="fas fa-location-arrow fa-sm"/></Link></h5>
                            </div>
                        </div>
                        {event.program ?
                            <div className="">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col" className="pl-0 pr-0">#</th>
                                        <th scope="col">
                                            {this.state.language === "hr" ? <div>Djelo</div> : <div>Composition</div>}
                                        </th>
                                        {event.title.includes("Film") ?
                                            <th scope="col">{this.state.language === "hr" ? <div>Redatelj</div> :
                                                <div>Director</div>}</th>
                                            :
                                            <th scope="col">{this.state.language === "hr" ? <div>Autor</div> :
                                                <div>Author</div>}</th>
                                        }
                                        {event.title.includes("Film") ?
                                            null
                                            :
                                            <th scope="col">{this.state.language === "hr" ? <div>Izvođači</div> :
                                                <div>Performers</div>}</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {event.program.map((composition, index) =>
                                        <tr key={index}
                                            className={(composition.author || '').toLowerCase().includes('papandopulo') ? "font-weight-bold" : ""}>
                                            <td className="font-weight-bold pl-0 pr-0">{index + 1}.</td>
                                            <td className="font-italic">
                                                <p>{composition.name}</p>
                                                {composition.movements ?
                                                    <div>
                                                        {composition.movements}
                                                    </div>
                                                    : null
                                                }
                                            </td>
                                            <td>{composition.author}</td>
                                            {event.title.includes("Film") || !composition.artists ?
                                                null
                                                :
                                                <td>{composition.artists.join(", ")}</td>
                                            }
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            null}
                    </div>
                )}
            </div>
        );
    }
}

export default Program;
