import React, {Component} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import PopupImage from "../../PopupImage/PopupImage";
import "./Photos.css";


let listOfCompressedImages = [];
let listOfHighResolutionImages = [];

class Photos2023 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImageViewer: false,
      popupImageSrc: null
    };

    listOfCompressedImages = Photos2023.importAll(require.context(`../../../../public/images/galerija/2023-compressed`, false, /\.(png|jpe?g|svg|JPG)$/));
    listOfHighResolutionImages = Photos2023.importAll(require.context(`../../../../public/images/galerija/2023`, false, /\.(png|jpe?g|svg|JPG)$/));
  }

  static importAll(r) {
    return r.keys().map(r);
  }

  showImageViewer(index) {
    this.setState({
      showImageViewer: !this.state.showImageViewer,
      popupImageSrc: listOfHighResolutionImages[index]
    });
  };

  hideImageViewer() {
    this.setState({
      showImageViewer: !this.state.showImageViewer
    });
  };

  render() {
    return (
      <div className="container page">
        <h1 className="mt-4 mb-4 text-center">
          Galerija Papandopulijane 2023
        </h1>

        <div className="row">
          {listOfCompressedImages.map((image, index) =>
            <div className="col-sm-3 p-2">
              <div className="photos-img-container">
                  <LazyLoadImage key={index}
                                 effect="opacity"
                                 className="photos-img"
                                 src={image}
                                 alt=""
                                 onClick={() => this.showImageViewer(index)}/>
                </div>
            </div>
          )}
        </div>
        {this.state.popupImageSrc ?
          <PopupImage
            imgsrc={this.state.popupImageSrc}
            show={this.state.showImageViewer}
            onHide={() => this.hideImageViewer()}
          />
          : null
        }
      </div>
    );
  }
}

export default Photos2023;
