import React, {Component} from 'react'
import './Contact.css';
import {organisers} from "../Contact/ContactData";
import {LazyLoadImage} from 'react-lazy-load-image-component';


class Contacts extends Component {
    render() {
        const organiser_cards = organisers.map(organiser => {
            return (
                <div key={organiser.tel} className="col-md-4 mb-4">
                    <div className="card h-100 text-center">
                        <div className="image-box-contact">
                            <LazyLoadImage className="img-fluid" src={organiser.img} alt="" effect="opacity"/>
                        </div>
                        <div className="card-body">
                            <h4 className="card-title">{organiser.name}</h4>
                            <h6>{organiser.role}</h6>
                            <hr/>
                            <p><a href={'mailto: ' + organiser.email + '?subject=Papandopulijana-Tribunj'}>
                                {organiser.email}</a>
                            </p>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div className="page">
                <div className="jumbotron jumbotron-fluid contact-jumbotron mb-3">
                    <div className="container">
                        <p className="lead">Papandopulijana Tribunj - Festival osnovan 2013. godine od Općine Tribunj i
                            Turističke
                            zajednice Općine Tribunj
                            pod pokroviteljstvom Šibensko-kninske županije. Umjetnički koncept festivala provodi udruga
                            Hrvatska
                            filharmonija uz potporu Hrvatskog kulturno-umjetničkog društva Tribunj i Kulturne udruge
                            Tribunj.</p>
                    </div>
                </div>
                <div className="container">

                    <h4 className="mb-2">Uprava festivala:</h4>
                    <div className="mb-2 pl-2">
                        <div className="font-weight-bold">Turistička zajednica Općine Tribunj</div>
                        <div>Adresa:&nbsp;
                            <a className="text-decoration-none"
                               href="http://maps.google.com/?q=BADNJE BB, 22212, Tribunj">Badnje
                                bb – 22212 Tribunj/Hrvatska</a>
                        </div>
                        <div>Tel:&nbsp;
                            <a href="tel:022446143">+385 224 461 43</a>
                        </div>

                        <div>Web:&nbsp;<a target="_blank" rel="noopener noreferrer"
                                          href="http://www.tz-tribunj.hr/">www.tz-tribunj.hr</a></div>
                    </div>

                    <div className="mb-2 pl-2">
                        <div className="font-weight-bold">Općina Tribunj</div>
                        <div>Tel:&nbsp;
                            <a href="tel:022446349">+385 224 463 49</a>
                        </div>
                        <div>Web:&nbsp;<a target="_blank" rel="noopener noreferrer"
                                          href="http://www.tribunj.hr/">www.tribunj.hr</a></div>
                    </div>

                    <div className="mb-2 pl-2">
                        <div className="font-weight-bold">Hrvatska filharmonija, Zagreb</div>
                        <div>Web:&nbsp;<a target="_blank" rel="noopener noreferrer"
                                          href="http://www.hrvatskafilharmonija.hr/">www.hrvatskafilharmonija.hr</a>
                        </div>
                    </div>

                    <hr/>

                    <h4 className="mb-2">Pokrovitelji festivala:</h4>
                    <div className="mb-2 pl-2">
                        <li className="font-weight-bold">Ministarstvo kulture Republike Hrvatske</li>
                        <li className="font-weight-bold">Šibensko-kninska županija</li>
                    </div>

                    <hr/>

                    <h4 className="mb-2">Donatori festivala:</h4>
                    <div className="mb-2 pl-2">
                        <li><strong>Zdenka Papandopulo</strong></li>
                        <li>Kulturna udruga Tribunj</li>
                        <li>Hrvatsko kulturno umjetničko društvo Tribunj</li>
                        <li>Stiftung Maritim Hermann & Milena Ebel - Hamburg</li>
                        <li>Marina Tribunj</li>
                        <li>Restoran Markiolac</li>
                    </div>

                    <hr/>

                    <h4 className="mb-2">Suradnici u izradi programskih tekstova i notnih materijala:</h4>
                    <div className="mb-2 pl-2">
                        <li>dr. sc. Ivana Tomić Ferić, muzikologinja - UMAS (Split/Šibenik)</li>
                        <li>dr. sc. Vito Balić, muzikolog - UMAS (Split)</li>
                        <li>dr. sc. Davor Merkaš, muzikolog - Muzički informativni centar (Zagreb)</li>
                        <li>mr. sc. Ljerka Orešković Herrmann, muzikologinja (Frankfurt a. M.)</li>
                        <li>Ana Vidić, muzikologinja (Zagreb)</li>
                    </div>

                    <hr/>

                    <div className="row">
                        {organiser_cards}
                    </div>
                </div>
            </div>
        );
    }
}

export default Contacts;
