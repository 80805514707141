export const festivals = [
    {
        "year": "2024",
        "artists": [
            {
                "name": " Kalamos kvintet",
                "links": [
                    {
                        "title": "O kvintetu",
                        "url": "https://www.facebook.com/kalamos.kvintet/"
                    },
                ]
            }
        ],
        "description": ``,
        "important_artists": ["Zagrebački kvartet", "Danae Papamattheou-Matschke & Uwe Matschke", "Sandra Bagarić i Darko Domitrović"],
        "images": [
            "/images/homepage/2024/image1.jpg",
            "/images/homepage/2024/image2.png",
            "/images/homepage/2024/image3.png",
            "/images/homepage/2024/image4.png",
            "/images/homepage/2024/image5.png",
            "/images/homepage/2024/image6.png",
            "/images/homepage/2024/image7.png",
        ]
    },
    {
        "year": "2023",
        "artists": [
            {
                "name": "Riječki puhački kvintet",
                "links": [
                    {
                        "title": "O kvintetu",
                        "url": "https://hnk-zajc.hr/predstava/rijecki-puhacki-kvintet/"
                    },
                ]
            },
            {
                "name": "Đani Stipaničev",
                "links": [
                    {
                        "title": "O umjetniku",
                        "url": "https://www.komedija.hr/www/ansambl-2/pjevaci/stipanicev-dani/"
                    },
                ]
            },
            {
                "name": "Klapa Ventula",
                "links": [
                    {
                        "title": "O klapi",
                        "url": "https://web.facebook.com/profile.php?id=100062958082600&_rdc=1&_rdr"
                    },
                ]
            },
            {
                "name": "Zagrebački tamburaški kvartet",
                "links": [
                    {
                        "title": "O kvartetu",
                        "url": "https://www.facebook.com/zagreb.tambura.quartet/"
                    },
                ]
            }
        ],
        "description": ``,
        "important_artists": ["Riječki puhački kvintet", "Đani Stipaničev", "Klapa Ventula", "Zagrebački tamburaški kvartet,"],
        "images": [
            "/images/homepage/2023/rijecki_puhacki_kvintet.png",
            "/images/homepage/2023/dani_stipanicev.png",
            "/images/homepage/2023/klapa_ventula.png",
            "/images/homepage/2023/zagrebacki_tamburaski_kvartet.png",
        ]
    },
    {
        "year": "2022",
        "artists": [
            {
                "name": "Hrvatska komorna filharmonija (HKF)",
                "links": [
                    {
                        "title": "O ansamblu",
                        "url": "https://www.hrvatskafilharmonija.hr/osor-ogv-2008-i-2009"
                    },
                    {
                        "title": "Tjedan Izraela: Hrvatski glazbeni zavod, koncert",
                        "url": "https://www.youtube.com/watch?v=MUPVbG-ruLg&t=1810s"
                    },
                ]
            },
            {
                "name": "Mario Korunić, violina",
                "links": [
                    {
                        "title": "O umjetniku",
                        "url": "https://www.m-k-o.eu/orchester/orchestermitglieder/mario-korunic/"
                    },
                    {
                        "title": "Mario Korunić: Toccata insomnia",
                        "url": "https://www.youtube.com/watch?v=hAgDEjXK0Dw"
                    },
                ]
            },
            {
                "name": "Klasja Modrušan, sopran",
                "links": [
                    {
                        "title": "O umjetnici",
                        "url": "https://www.klasja-soprano.com/"
                    },
                    {
                        "title": "Ludwig van Beethoven: Ah, perfido",
                        "url": "https://www.youtube.com/watch?v=X0qJxIJUExs"
                    },
                ]
            },
            {
                "name": "Benjamin Šuran, bariton",
                "links": [
                    {
                        "title": "O umjetniku",
                        "url": "https://hnk-zajc.hr/clanhnk/benjamin-suran/"
                    },
                    {
                        "title": "Gaetano Donizetti: Ljubavni napitak - Nemorino",
                        "url": "https://www.youtube.com/watch?v=JLrBzYWwwis&t=2176s"
                    },
                ]
            },
            {
                "name": "Gitanes Blondes",
                "links": [
                    {
                        "title": "O ansamblu",
                        "url": "https://www.gitanes-blondes.de/Info/index.php"
                    },
                    {
                        "title": "Samos Young Artists Festival: Open air koncert",
                        "url": "https://www.youtube.com/watch?v=gDIl_bFKpCg"
                    },
                ]
            }
        ],
        "description": `Dragi prijatelji, deseto jubilarno izdanje festivala Papandopulijana u kolovozu nestrpljivo vas očekuje! HVALA svim
poklonicima klasične glazbe, plesa, filmske glazbe i dalmatinske pjesme na dosadašnjim posjetiima 28 priredbi i
prepoznavanju Papandopulova jedinstvenog opusa. Vjerujte nam, nećemo iznevjeriti vaša očekivanja prepoznate,
predane i vjerne publike!`,
        "important_artists": ["Hrvatska komorna filharmonija (HKF)", "Gitanes Blondes", "Benjamin Šuran", "Klasja Modrušan"],
        "images": [
            "/images/homepage/2022/benjamin_suran.png",
            "/images/homepage/2022/gitanes_blondes.png",
            "/images/homepage/2022/klasja_modrusan.png",
            "/images/homepage/2022/mario_korunic.png"
        ]
    },
    {
        "year": "2021",
        "artists": [
            {
                "name": "Klavirski duo Zrinka Ivančić i Dalibor Cikojević",
                "description": "Njihova odmjerenost i vladanje instrumentima i ovom kompozicijom stvorili su dojam kao da si i oni sami sudjelovali u njezinu skladanju."
            },
            {
                "name": "Udaraljkaši Filip Merčep i Fran Krsto Šercar",
                "description": "Svojim mnogobrojnim projektima konstantno dokazuju da su umjetnici širokog spektra interesa i djelovanja"
            },
            {
                "name": "Plesači Akademije dramske umjetnosti u Zagrebu",
                "description": "Linda Tarnovski i Anika Cetina,  Koreografija: V. Bubalo/Š. Stankov"
            },
            {
                "name": "Zorica Kondža i Joško Banov ansambl"
            }
        ],
        "description": `Drugu godinu zaredom unatoč koronavirusu i na propisanom odstojanju ostajemo bliski u cilju približavanja Papandopulova jedinstvenog i raznolikog opusa svekolikoj publici: poklonicima klasične glazbe, plesa, filmske glazbe i dalmatinske pjesme. U godini obilježavanja tridesete obljetnice smrti hrvatskog velikana, glazbeni i plesni umjetnici predstaviti će vjernoj publici Papandopulov “Horoskop”, plesnu suitu za dva glasovira i udaraljke op. 52 ” koju je maestro započeo skladati u Zagrebu a završio u Splitu, srcu Dalmacije u kolovozu 1935. godine.`,
        "important_artists": ["Zrinka Ivančić i Dalibor Cikojević", "Filip Merčep i Fran Krsto Šercar", "Zorica Kondža i Joško Banov ansambl"],
        "images": [
            "/images/homepage/2021/ivancic_cikojevic.jpg",
            "/images/homepage/2021/filip_mercep.jpg",
            "/images/homepage/2021/fran_krsto.jpg",
            "/images/homepage/2021/zorica_kondza.jpg"
        ]
    },
    {
        "year": "2020",
        "artists": [
            {
                "name": "Zagrebački kvartet",
                "description": "Odsvirao je preko 4.000 koncerata na svim kontinentima, snimio više od 60 ploča za razne svjetske izdavačke i radijske kuće."
            },
            {
                "name": "Danae Papamattheou-Matschke & Uwe Matschke",
                "description": "Njihovo sviranje dalo je snažne interpretacije koje su kombinirale strukturni integritet, unutarnji ritmički puls i matematičku jasnoću."
            },
            {
                "name": "Sandra Bagarić i Darko Domitrović",
                "description": "Njihovo muziciranje uvijek govori o ljubavi i sretnim završetcima."
            }
        ],
        "description": "I na propisanom odstojanju unatoč koronavirusu ostajemo bliski u cilju približavanja Papandopulova jedinstvenog i raznolikog opusa svekolikoj publici: poklonicima klasične glazbe, filmske glazbe i dalmatinske pjesme. Umjetnici izvođači predstaviti će dio komornog opusa koji je maestro Papandopulo ostavio budućim naraštajima, ujedno i obilježiti 250. obljetnicu rođenja glazbenog velikana Ludwiga van Beethovena. Poželjeli smo da Borisova obiteljska geneza bude vidljiva u odabiru umjetnika i programu ovogodišnjeg festivala.",
        "important_artists": ["Zagrebački kvartet", "Danae Papamattheou-Matschke & Uwe Matschke", "Sandra Bagarić i Darko Domitrović"],
        "images": [
            "/images/homepage/2020/bagaric_sandra.jpg",
            "/images/homepage/2020/danaematschke.jpg",
            "/images/homepage/2020/zagrebacki_kvartet.jpg"
        ]
    },
    {
        "year": "2019",
        "description": "",
        "artists": [],
        "important_artists": ["Zagrebački solisti i Filip Merčep", "Jacques Houdek"],
        "images": [
            "/images/homepage/2019/6501.jpg",
            "/images/homepage/2019/5329.jpg",
            "/images/homepage/2019/naslovna_2019.jpg"
        ]
    },
    {
        "year": "2018",
        "description": "",
        "artists": [],
        "important_artists": ["Hrvatski gudački kvartet i Aljoša Jurinić", "Đani Stipaničev"],
        "images": [
            "/images/homepage/2018/aljosa_jurinic.jpg",
            "/images/homepage/2018/hr_kvartet.jpg",
            "/images/homepage/2018/dani_stipanicev.jpg"
        ]
    },
    {
        "year": "2017",
        "description": "",
        "artists": [],
        "important_artists": ["Riječki klavirski trio i Klasja Modrušan", "Radojka Šverko"],
        "images": [
            "/images/homepage/2017/radojka_sverko.jpg",
            "/images/homepage/2017/rijecki_klavirski_trio.jpg",
            "/images/homepage/2017/klasja_modrusan.jpg"
        ]
    },
    {
        "year": "2016",
        "description": "",
        "artists": [],
        "important_artists": ["Gudački kvartet Rucner i Bruno Philipp", "Ana Rucner"],
        "images": [
            "/images/homepage/2016/ana_rucner.jpg",
            "/images/homepage/2016/bruno_philipp.jpg",
            "/images/homepage/2016/rucner_kvartet.jpg"
        ]
    },
    {
        "year": "2015",
        "description": "",
        "artists": [],
        "important_artists": ["Cantus ansambl i Evgenia Epshtein", "Studio za suvremeni ples Zagreb", "Klapa More (Šibenik) i Marko Škugor"],
        "images": [
            "/images/homepage/2015/evgenia_epshtein.jpg"
        ]
    },
    {
        "year": "2014",
        "description": "",
        "artists": [],
        "important_artists": ["Mandolinski orkestar HKUD Tribunj", "Nonett der HMT - Rostock", "Plesna udruga \"Sjene", "Srđan Čaldarović i Lana Bradić", "Klapa More (Šibenik) i Marko Škugor"],
        "images": [
            "/images/homepage/2014/nonett_der_HMT.jpg"
        ]
    },
    {
        "year": "2013",
        "description": "",
        "artists": [],
        "important_artists": ["Hrvatska komorna filharmonija i Marco Graziani", "Plesna skupina Umjetničke škole F. Lučić - Velika Gorica, solisti Ana Batinica (flauta) i Lana Bradić (klavir)", "Klapa More (Šibenik) i Đani Stipaničev"],
        "images": [
            "/images/homepage/2013/balet.jpg"
        ]
    }
];

