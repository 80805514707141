import React, {Component} from 'react';
import './Exhibition.css';

class ExhibitionInfo extends Component {
    render() {
        return (
            <div className="container mt-2 page">
                <div>
                    <h2 className="mb-3 mt-4 font-italic">Boris Papandopulo i Tribunj</h2>
                    <p className="text-justify">
                        S puno entuzijazma i životne energije, Boris Papandopulo intenzivno je i nadahnuto skladao
                        gotovo do dana smrti, punih 65 godina. U svojim ostvarajima potvrđivao se kao majstor svog
                        metijea – duhoviti glazbenik neiscrpne i svježe inspiracije, besprijekornog talenta i
                        nepogrešivog osjećaja za glazbeno-vremenski tijek, logički i estetički. Posljednjih petnaest
                        godina života boravio je u Tribunju sa suprugom Zdenkom i u tom iznimno plodnom periodu
                        stvaralaštva skladao 170 djela, više od trećine cjelokupnog životnog opusa.</p>
                    <p className="text-justify">
                        Nakon što su saznali da ugledni diplomat, filozof, istaknuti nadrealistički pjesnik i književnik
                        Konstantin Koča Popović prodaje kuću u Tribunju, Boris i Zdenka zaputili su se u Beograd, na
                        Dedinje kako bi s Lepom Perović dogovorili kupnju kuće u tom pitoresknom ribarskom mjestašcu
                        vrlo autentičnog graditeljskog nasljeđa s uskim ulicama i zbijenim kućama, svega petnaestak
                        kilometara udaljenom od Šibenika. Odabir je bio slučajan, ali u izvjesnom smislu znakovit.
                        Naime, upravo je Tribunj, nekadašnji Triubahunj, svojevrsna umjetnička kolonija, prostor koji
                        gotovo magnetički privlači umjetnike da u tišini njegova spokoja osluškuju svoj unutrašnji
                        svijet. Došavši u Tribunj, i Papandopulo je osjetio sklad takva okruženja, obgrlivši njegovu
                        prirodnu izvornost. </p>
                    <p className="text-justify">
                        Iskustvo, ne samo umjetničko, uči nas da je prostor sudbina, sa svim svojim mogućnostima koje
                        otvara, ali i s "ograničenjima" koje nameće. Kao istinski zaljubljenik u Dalmaciju, privučen
                        beskrajnim dodirom obzorja i pučine, srođen s kontrastima mistike i lirike toga podneblja,
                        Papandopulo je stvaralac duboko ukorijenjen u regionalni humus, svjestan naraštaja koji su mu
                        prethodili i kulturnih taloga koji su ga oblikovali. Glazbeno nasljeđe u njegovoj imaginaciji
                        odjekuje visokim registrima postojane arome i kreativne domišljatosti kojom obnavlja
                        prepoznatljiva primarna ishodišta u nove, sasvim originalne glazbene ideje vješto
                        interpolirajući vlastitu stvaralačku ličnost unutar "povijesne krivulje". Baštinske vrijednosti
                        i uskladišteni intonativno-ritamski uzorci, stvarni su i imaginativni predložak, duhovno
                        prethodništvo i ljepota kojom skladatelj dohranjuje svoje glazbene vizije. Oni su dragocjena
                        građa, ali i autonomna vrijednost, predtekst za osuvremenjeni glazbeni kontekst iz kojega izbija
                        snaga Papandopulova talenta i vještina stilizacije. Vidljivo je to u brojnim njegovim
                        partiturama, od Muke gospodina našega Isukrsta, preko Poljičke pučke mise, brojnih vokalnih
                        djela do klapskih pjesama (Ostav' se više mora) koje su u glazbenoj baštini Dalmacije bez sumnje
                        onaj tradicijski izbor koji su vrijeme i okruženje potvrdili kao vrijednost i suglasno preuzeli
                        u sadašnjost.</p>
                    <p className="text-justify">
                        Isplativši kuću desetogodišnjom ušteđevinom iz rada u Njemačkoj, Boris i Zdenka prepustili su se
                        čarima života u neposrednoj blizini mora s dva mala brodića za zabavu, jedan od poštarice
                        Antice, a drugi Zdenkin, dopremljen iz Njemačke. „Boris je imao ključeve i čekao moj konačni
                        povratak“, ispričala nam je Papandopulova muza, „uselio se i uživao sa druželjubivim
                        zavičajnicima...živio je kao na brodu...“ Maestro nikada nije bio formalan, glazbenike je
                        tretirao jednako srdačno, neovisno o tome jesu li bili studenti ili pak glazbenici velika ugleda
                        i iskustva. U Tribunj su dolazili interpreti, skladatelji, muzikolozi, pjevači iz svih teatara
                        sa prostora bivše države, kuća je prštala energijom i dobrim vibracijama, uvijek prepuna
                        gostiju. Papandopulo je besprijekorno poznavao mogućnosti pojedinih glazbala, ali poznavao je i
                        umjetnike kojima je ili posvećivao ili za njih pisao pojedine skladbe. Bio je bogat idejama
                        kojima je neštedimice obasipao svoje zapise, ostajući jedan od stožernih skladatelja hrvatske
                        glazbe minuloga stoljeća. Bio je čovjek s nevjerojatnim radnim navikama, budio se ujutro sa
                        suncem, a potom sjedao za klavir koji je za tribunjski dom bio naručen i dopremljen iz Beograda.
                        Skladao bi do devet (osim ako nije radio neku veću i opsežniju narudžbu) nakon čega bi sa
                        suprugom Zdenkom, gotovo ritualno, uživao u „čajeku“. Pri skladanju, nikada nije radio skice,
                        već je partituru pisao izravno, „iz glave na papir“, uvijek s jasno oblikovanim temama bez
                        obzira jesu li lapidarne i ritmički pregnantne ili su široko zasnovane i pjevne. Veliki broj
                        partitura odlikuje i originalan papandopulovski prštav, gotovo zarazni glazbeni humor u kojem
                        često citira i teme drugih autora. Ta životna vedrina i karizma optočena optimizmom privlačila
                        je oko njega ne samo umjetnike/suradnike već i sumještane, ribare, težake, pučke pivače. Mnogi
                        od njih zasigurno su nosili u sebi reminiscencije skladateljeva duha i vedrine. </p>
                    <p className="text-justify">
                        Tribunjski period Papandopulova stvaralačkog djelovanja iznjedrio je niz glazbenih djela velike
                        umjetničke vrijednosti. Na nagovor Željka Strake, skladateljeva kuma i prijatelja, također
                        glazbenika koji je često pohodio Tribunj, nastale su Četiri invencije za Cameratu. Djelo je
                        skladano za komorni orkestar Camerata musica Berlin, ansambl sjajnih solista iz tadašnje
                        Njemačke Demokratske Republike, tj. Istočnog Berlina, koji je utemeljio upravo Straka. Invencije
                        su nastale u Tribunju od 2. lipnja do 30. srpnja 1985., a zamišljene su po uzoru na istoimene
                        barokne instrumentalne forme i pisane u duhu tadašnje izvedbene prakse koja je od svakog
                        pojedinog glazbenika u ansamblu zahtijevala nemalo sviračko umijeće: iznimno visok stupanj
                        ovladavanja tehnike instrumenta, a istovremeno i njihovu minucioznu međusobnu usklađenost. Na
                        poticaj Papndopulove kćerke, Maje Papandopulo- Mijač, u Tribunju je, kolovoza 1990., dovršena i
                        originalna partitura Preludija za orgulje posvećena fra Ivanu (Srećku) Peranu, autoru nadaleko
                        poznate Hrvatske mise. U tribunjskoj kući Papandopulo je 1987. skladao kantatu Himna suncu za
                        veliki orkestar, bariton i mješoviti zbor na stihove pjesnika Luke Paljetka. Posvećena je
                        dubrovačkom dirigentu Ivi Dražiniću koji je potaknuo njezin nastanak i koji je bio osobito
                        omiljen tribunjski gost. Premda je inicijalna ideja hvalospjeva suncu povezala temeljne
                        identifikacijske oznake onih kultura koje su egzistencijalno vezane uz kult sunca, autorova je
                        opčinjenost mediteranskim prostorom dobila svoj puni izraz u finalnoj apoteozi. Skladba je
                        trebala biti praizvedena na Dubrovačkim ljetnim igrama, ali je na susret s publikom morala
                        čekati više od dvadeset godina. Tek u studenome 2010. u zagrebačkoj Koncertnoj dvorani
                        Vatroslava Lisinskog, kantata je doživjela prvu javnu izvedbu pod vodstvom maestra Pavla
                        Dešpalja, velikog štovatelja Papandopulova stvarateljskog djelovanja. Kontakt s isusovcem
                        Valentinom Pozaićem naputio ga je na poemu kazališnog redatelja, književnika, teatrologa i
                        prevoditelja Vojmila Rabadana s naslovom Carmen Boscovichianum iliti Spomen mali velikom nam
                        Rudi uz 200. obljetnicu smrti dubrovačkog učenjaka Ruđera Josipa Boškovića. Na tom predlošku,
                        Papandopulo je skladao istoimenu kantatu i posvetio je Pozaiću, a djelo je praizveo ansambl
                        Collegium pro musica sacra. Na poticaj dirigenta Željka Strake da komponira djelo za Rostock
                        Nonett, 1985. nastala je i Komorna simfonija za puhački kvintet i gudački trio s kontrabasom.
                        Djelo su praizveli Cantus ansambl i dirigent Berislav Šipuš u kolovozu 2003. Tribunjsku naznaku
                        na originalnoj partituri imaju i Tri kompozicije za glasovir koje je 1986. Papandopulo posvetio
                        pijanistici Ljerki Pleslić Bjelinski, zatim Treća sonata za glasovir skladana u proljeće 1989.,
                        djela posvećena violončelistu Valteru Dešpalju, popijevke s posvetom sopranistici Cynthiji
                        Hansell Bakić (Serenada, Daj, ruku daj!), Divertimento alla pasticcio koje je Papandopulo
                        napisao 1971. prema narudžbi njemačkog grada Hernea i svog prijatelja Ljubomira Romanskog
                        „glazbenog direktora grada Gelsenkirchena“ za potrebe upoznavanja mladeži s orkestrom. </p>
                    <p className="text-justify">
                        Ovo je, međutim, tek djelićak onoga što je Papandopulo stvorio u svojoj kući u Tribunju,
                        potvrđujući da je, nakon Zajca, a uz Bruna Bjelinskog, nedvojbeno najplodniji hrvatski
                        skladatelj. Prema Borisovu vlastoručnom popisu koji čuva njegova supruga Zdenka, Papandopulo je
                        skladao gotovo 490 opusa u koji se ubrajaju i obrade te instrumentacije tuđih djela. Nažalost,
                        za čitav niz djela, budući da ih je skladatelj pisao i dijelio interpretima, a da nikada nije
                        radio kopije, donedavna nije bilo poznato gdje se nalaze i jesu li sačuvana. Dobar dio njih (oko
                        sedamdesetak), nakon dvogodišnje potrage po tavanima, podrumima, garažama i važnim arhivima
                        Njemačke, Italije, Srbije, Slovenije, Francuske, Egipta, SAD-a i Hrvatske otkrio je (uz
                        nesebičnu pomoć skladateljeve supruge) muzikolog Davor Merkaš otvorivši put cjelovitijoj obradi,
                        sagledavanju i vrednovanju maestrova opusa.
                    </p>
                    <p className="text-justify">
                        „Boris Papandopulo – aristokrat podrijetlom ali i duhom“ kako je to poetično izrekla
                        muzikologinja Erika Krpan, ostat će upamćen kao jednostavan, neusiljen, blag i ljubazan čovjek
                        čija glazba zrcali snažnu stvaralačku individualnost i bez obzira na vrijeme nastanka ili
                        stilsku provenijenciju vrlo lako i spontano pronalazi neposredan dodir sa slušateljstvom.
                        Papandopulijana Tribunj, u čast velikog Maestra, podsjetit će na neizbrisiv trag koji je
                        skladatelj ucrtao u prostor i vrijeme, s namjerom da oživi, rekonstruira i sačuva njegova
                        antologijska djela koja do danas nisu izvedena niti objavljena, a na radost brojnih poklonika
                        glazbene umjetnosti širom svijeta. U tom smislu, zaželjeti je da, bogateći krvotok tribunjskog
                        kulturnog ljeta, festival traje te svojom kvalitetom i progresivnim nastojanjima izgrađuje
                        prepoznatljivu vlastitost. Dobrodošli!
                    </p>
                    <p className="text-right font-italic">dr. sc. Ivana Tomić Ferić</p>
                </div>
            </div>
        );
    }
}

export default ExhibitionInfo;

