import React, {Component} from 'react'
import './Location.css';

class Location extends Component {
    render() {
        return (
            <div className="container page">
                <div className="row mt-4">
                    <div className="col-lg-9 mb-6 map-container">
                        <iframe title="Karta događaja Papandopulijane"
                                src="https://www.google.com/maps/d/embed?mid=1JCQ94FCWv5Lfh3WgTCKeMeR2y2Uij78U&hl=hr"
                                width="100%" height="100%"/>
                    </div>
                    <div className="col-lg-3 mb-6 p-3">
                        <h3 className="mt-2">Lokacije:</h3>
                        <br/>
                        <h6>Trg Škver</h6>
                        Tribunj, Hrvatska
                        <br/>
                        <br/>
                        <h6>Kulturni centar Tribunj</h6>
                        Tribunj, Hrvatska
                        <br/>
                        <br/>
                        <h6>Crkva Velike Gospe</h6>
                        Trg Pijaca 1, 22211, Tribunj, Hrvatska
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Location;