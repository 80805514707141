import React from 'react';
import {Link} from 'react-router-dom';
import './NotFoundPage.css';

const NotFoundPage = () => (
    <div className="container page">
        <div className="row">
            <div className="col-md-12">
                <div className="error-template">
                    <h1>Ops!</h1>
                    <h2>Error 404 Not Found</h2>
                    <div className="error-details mt-1">
                        <h5>Greška se dogodila, tražena stranica ne postoji!</h5>
                    </div>
                    <div className="error-actions">
                        <Link to="/" className="btn btn-outline-primary btn-lg">
                            <i className="fas fa-home"/>
                            &nbsp;Povratak na naslovnicu
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default NotFoundPage;
