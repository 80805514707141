import React, {useState} from 'react';
import './MyCarousel.css'
import {Carousel, CarouselControl, CarouselIndicators, CarouselItem} from 'reactstrap';
import {LazyLoadImage} from 'react-lazy-load-image-component';

const MyCarousel = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const images = Array.from(props.images);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = images.map((image) => {
        return (
            <CarouselItem
                className="custom-carousel"
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={image}
            >
                <LazyLoadImage src={image} effect="opacity" width="100%" height="100%"/>
            </CarouselItem>
        );
    });

    return (
        <>
            <Carousel className="my-carousel"
                      activeIndex={activeIndex}
                      next={next}
                      previous={previous}
                      interval={props.interval}
            >
                {props.indicators && images.length > 1 ?
                    <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex}/>
                    :
                    null
                }
                {slides}

                {props.controls ?
                    <>
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
                    </>
                    :
                    null
                }

            </Carousel>
        </>
    );
};

export default MyCarousel;