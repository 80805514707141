export const programs = {
    "2013": [
        {
            "date": "07.08.2013.",
            "title": "Svečani koncert otvorenja festivala",
            "location": "Trg Škver",
            "starting_time": "21:00",
            "program": [
                {
                    "name": "Himna Republike Hrvatske",
                    "author": "Josip Runjanin",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“", "Hrvatska komorna filharmonija"]
                },
                {
                    "name": "Ostav se više mora",
                    "author": "Boris Papandopulo",
                    "artists": ["Klapa „More“", "Đani Stipaničev"]
                },
                {
                    "name": "Adagio",
                    "author": "Tomaso Albinoni/Remo Giazotto",
                    "artists": ["Marco Graziani", "Hrvatska komorna filharmonija"]
                },
                {
                    "name": "Jednostavna simfonija op. 4",
                    "author": "Benjamin Britten",
                    "artists": ["Marco Graziani", "Hrvatska komorna filharmonija"]
                },
                {
                    "name": "Četiri invencije za Cameratu",
                    "author": "Boris Papandopulo",
                    "artists": ["Marco Graziani", "Hrvatska komorna filharmonija"]
                },
                {
                    "name": "Teuta",
                    "author": "Boris Papandopulo",
                    "artists": ["Ana Batinica", "Lana Bradić  ", "Plesna skupina Umjetničke škole Franjo Lučić-Velika Gorica"]
                }
            ]
        },
        {
            "date": "08.08.2013.",
            "title": "Koncert sakralne glazbe",
            "location": "Crkva Velike Gospe",
            "starting_time": "21:00",
            "program": [
                {
                    "name": "Toccata i fuga u d-molu BWV 565 za orgulje solo",
                    "author": "Johann Sebastian Bach",
                    "artists": ["Ivan Bosnar"]
                },
                {
                    "name": "Agnus dei, arija soprana iz „Krunidbene mise“",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Klasja Modrušan", "Ivan Bosnar"]
                },
                {
                    "name": "Salve Regina ",
                    "author": "Felix Mendelssohn",
                    "artists": ["Klasja Modrušan"]
                },
                {
                    "name": "Aleluja za sopran i orgulje",
                    "author": "Emil Cossetto",
                    "artists": ["Klasja Modrušan", "Ivan Bosnar"]
                },
                {
                    "name": "Preludij za orgulje ",
                    "author": "Boris Papandopulo",
                    "artists": ["Ivan Bosnar"]
                },
                {
                    "name": "Vox animae",
                    "author": "Franjo Dugan Stariji",
                    "artists": ["Klasja Modrušan", "Ivan Bosnar"]
                },
                {
                    "name": "Ave Maria",
                    "author": "Franz Schubert",
                    "artists": ["Iva Ivković"]
                },
                {
                    "name": "Pozdrav zori dalmatinskoj",
                    "author": "Boris Papandopulo",
                    "artists": ["Klasja Modrušan"]
                },
                {
                    "name": "Kad igra kolo",
                    "author": "Boris Papandopulo",
                    "artists": ["Klasja Modrušan", "Ivan Bosnar"]
                }
            ]
        },
        {
            "date": "08.08.2013.",
            "title": "Filmska projekcija",
            "starting_time": "22:30",
            "location": "Atrij Kulturnog centra Tribunj",
            "program": [
                {
                    "name": "Film „Lisinski“ (1944.)",
                    "author": "Oktavijan Miletić",
                    "artists": ["Boris Papandopulo", "Vatroslav Lisinski"]
                }
            ]
        }
    ],
    "2014": [
        {
            "date": "06.08.2014.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "1. studija za harfu solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Mirjana Krišković"]
                },
                {
                    "name": "2. studija za harfu solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Mirjana Krišković"]
                },
                {
                    "name": "Mala suita za gudače ",
                    "author": "Boris Papandopulo",
                    "artists": ["Mandolinski orkestar HKUD Tribunj"]
                }
            ]
        },
        {
            "date": "06.08.2014.",
            "title": "Papandopulijana mladih umjetnika",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Odabrane skladbe",
                    "author": "Boris Papandopulo",
                    "artists": ["Učenici GŠ Šibenik, Knin i Drniš"]
                },
                {
                    "name": "Odabrane skladbe",
                    "author": "Boris Papandopulo",
                    "artists": ["Studenti UMAS i Split", "Lana Bradić "]
                },
                {
                    "name": "Musikhochschule  Rostock",
                    "author": "Boris Papandopulo",
                    "artists": ["Studenti UMAS i Split", "Lana Bradić "]
                }
            ]
        },
        {
            "date": "07.08.2014.",
            "title": "Svečani koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Himna Republike Hrvatske",
                    "author": "Josip Runjanin",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Dalmatinske pučke popijevke ",
                    "author": "Boris Papandopulo",
                    "artists": ["Šibensko pjevačko društvo Kolo"]
                },
                {
                    "name": "Eine Kammesinfonie",
                    "author": "Boris Papandopulo",
                    "artists": ["Rostocker Nonett"]
                }
            ]
        },
        {
            "date": "08.08.2014",
            "title": "Koncert sakralne glazbe",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Aleluja za sopran, tenor i orgulje",
                    "author": "Boris Papandopulo",
                    "artists": ["Mješoviti pjevački zbor  „Vox Animae“", "Ana Karuza Lovrić", "Božimir Lovrić"]
                },
                {
                    "name": "Poljička pučka misa",
                    "author": "Boris Papandopulo",
                    "artists": ["Mješoviti pjevački zbor  „Vox Animae“", "Božimir Lovrić"]
                },
                {
                    "name": "Film „Pustolov pred vratima“(1961)",
                    "author": "Šime Šimatović",
                    "artists": ["Boris Papandopulo"]
                }
            ]
        }
    ],
    "2015": [
        {
            "date": "03.08.2015.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Točka iznenađenja",
                    "author": "Boris Papandopulo",
                    "artists": ["GŠ Ivana Lukačića"]
                }
            ]
        },
        {
            "date": "03.08.2015.",
            "title": "Papandopulijana mladih umjetnika ",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Skladbe iz komornog opusa",
                    "author": "Boris Papandopulo",
                    "artists": ["Studenti akademije u Splitu", "Lana Bradić"]
                }
            ]
        },
        {
            "date": "03.08.2015.",
            "title": "Filmska projekcija",
            "starting_time": "22:00",
            "location": "Atrij Kulturnog centra Tribunj",
            "program": [
                {
                    "name": "Jadran kroz vjekove (1950)",
                    "author": "",
                    "artists": ["Boris Papandopulo"]
                }
            ]
        },
        {
            "date": "04.08.2015.",
            "title": "Svečani koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Himna Republike Hrvatske",
                    "author": "Josip Runjanin",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“", "Klapa „More“"]
                },
                {
                    "name": "Ostav se više mora",
                    "author": "Boris Papandopulo",
                    "artists": ["Klapa „More“"]
                },
                {
                    "name": "Ružice rumena",
                    "author": "Boris Papandopulo",
                    "artists": ["Klapa „More“", "Đani Stipaničev"]
                },
                {
                    "name": "Kroz glazbenu prošlost",
                    "author": "Boris Papandopulo",
                    "artists": ["Cantus ansambl", "Studio za suvremeni ples, Zagreb"]
                },
                {
                    "name": "Komorni koncert za violinu solo i puhački kvintet",
                    "author": "Boris Papandopulo",
                    "artists": ["Evgenija Epshtein", "Cantus ansambl"]
                }
            ]
        },
        {
            "date": "05.08.2015",
            "title": "Koncert sakralne glazbe",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Kanon u D duru",
                    "author": "Johann Pachelbell",
                    "artists": ["Gudački kvartet Rucner"]
                },
                {
                    "name": "Ave Maria",
                    "author": "Giulio Caccini",
                    "artists": ["Ivan Bosnar", "Klasja Modrušan"]
                },
                {
                    "name": "Djetelina s četiri lista",
                    "author": "Boris Papandopulo",
                    "artists": ["Gudački kvartet Rucner"]
                },
                {
                    "name": "Gudački kvartet br. 3 „Narodni“",
                    "author": "Boris Papandopulo",
                    "artists": ["Gudački kvartet Rucner"]
                },
                {
                    "name": "Poljička pučka misa za mješoviti zbor i orgulje",
                    "author": "Boris Papandopulo",
                    "artists": ["Schola cantorum Umjetničke akademije u Splitu", "Ivan Bosnar"]
                },
                {
                    "name": "Laudate Dominum KV 339",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Schola cantorum Umjetničke akademije u Splitu", "Ivan Bosnar", "Klasja Modrušan"]
                }
            ]
        }
    ],
    "2016": [
        {
            "date": "06.08.2016.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj",
            "artists": [" "]
        },
        {
            "date": "06.08.2016.",
            "title": "Papandopulijana mladih umjetnika ",
            "starting_time": "21:30",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Partita za klavir solo",
                    "author": "Boris Papandopulo",
                    "artists": [" "]
                },
                {
                    "name": "Varijacije za klavir ",
                    "author": "František Černoch",
                    "artists": [" "]
                }
            ]
        },
        {
            "date": "03.08.2016.",
            "title": "Filmska projekcija",
            "starting_time": "22:15",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Srdačno Vaši (1985)",
                    "author": "Ivan Hetrich",
                    "artists": ["Boris Papandopulo"]
                }
            ]
        },
        {
            "date": "07.08.2016.",
            "title": "Svečani koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Himna Republike Hrvatske",
                    "author": "Josip Runjanin",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“", "Klapa „More“"]
                },
                {
                    "name": "Klarinet kvintet",
                    "author": "Boris Papandopulo",
                    "artists": ["Gudački kvartet Rucner", "Bruno Philipp"]
                },
                {
                    "name": "Klarinet kvintet Op. 34",
                    "author": "Carl Maria von Weber",
                    "artists": ["Ana Rucner", "Gudački kvartet Rucner"]
                },
                {
                    "name": "Mala slavonska rapsodija",
                    "author": "Tomislav Uhlik",
                    "artists": ["Gudački kvartet Rucner", "Bruno Philipp"]
                },
                {
                    "name": "Schindler’s list",
                    "author": "John Williams",
                    "artists": ["Gudački kvartet Rucner"]
                },
                {
                    "name": "Oblivion",
                    "author": "Astor Piazzolla",
                    "artists": ["Ana Rucner"]
                },
                {
                    "name": "Libertango",
                    "author": "Astor Piazzolla",
                    "artists": ["Ana Rucner"]
                },
                {
                    "name": "Glazbeni kolaž",
                    "author": "",
                    "artists": ["Ana Rucner"]
                }
            ]
        },
        {
            "date": "09.08.2016",
            "title": "Koncert sakralne glazbe",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Koncert u D Duru - Il gardellino",
                    "author": "Antonio Vivaldi",
                    "artists": ["Kvartet flauta 4Syrinx"]
                },
                {
                    "name": "Matthäus-Passion BWV 244",
                    "author": "Johann Sebastian Bach",
                    "artists": ["Leon Košavić"]
                },
                {
                    "name": "Passacaglia za orgulje solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Pavao Mašić"]
                },
                {
                    "name": "Suita za dvije flaute solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Kvartet flauta 4Syrinx"]
                },
                {
                    "name": "Paulus - Gott, sei mir gnädig",
                    "author": "Felix Mendelssohn",
                    "artists": ["Leon Košavić"]
                },
                {
                    "name": "Pet pjesama bez riječi",
                    "author": "F. Mendelssohn Bartholdy",
                    "artists": ["Leon Košavić"]
                },
                {
                    "name": "Tri stavka za kvartet flauti",
                    "author": "Boris Papandopulo",
                    "artists": ["Kvartet flauta 4Syrinx"]
                },
                {
                    "name": "Chants d'Espagne, op.232, br.1",
                    "author": "Isaac Albeniz",
                    "artists": ["Leon Košavić"]
                },
                {
                    "name": "Lapadski soneti",
                    "author": "Boris Papandopulo",
                    "artists": ["I. Vojnović", "Leon Košavić", "4Syrinx", "Pavao Mašić"]
                },
                {
                    "name": "Ombra mai fu, arija iz opere Xerxes",
                    "author": "Isaac Albeniz",
                    "artists": ["Leon Košavić", "4Syrinx", "Pavao Mašić"]
                }
            ]
        }
    ],
    "2017": [
        {
            "date": "06.08.2017.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "06.08.2017.",
            "title": "Papandopulijana mladih umjetnika ",
            "starting_time": "21:30",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Skladbe za glasovir i klasičan program recitala za glasovir",
                    "author": "Boris Papandopulo",
                    "artists": ["Elizabeta Adžaga"]
                }
            ]
        },
        {
            "date": "03.08.2017.",
            "title": "Filmska projekcija",
            "starting_time": "22:15",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Srdačno Vaši (1985)",
                    "author": "Ivan Hetrich",
                    "artists": ["Boris Papandopulo"]
                }
            ]
        },
        {
            "date": "07.08.2017.",
            "title": "Svečani koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Himna Republike Hrvatske",
                    "author": "Josip Runjanin",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Dvije dalmatinske pučke popijevke                                                          ",
                    "author": "Boris Papandopulo",
                    "artists": ["Riječki klavirski trio"]
                },
                {
                    "name": "Papandopulijada",
                    "author": "Boris Papandopulo",
                    "artists": ["Riječki klavirski trio"]
                },
                {
                    "name": "Scottish Folk Songs Op. 108 & 227",
                    "author": "Ludwig van Beethoven",
                    "artists": ["Klasja Modrušan", "Riječki klavirski trio"]
                },
                {
                    "name": "Četiri godišnja doba",
                    "author": "Astor Piazzolla",
                    "artists": ["Riječki klavirski trio"]
                },
                {
                    "name": "Glazbeni kolaž",
                    "author": "",
                    "artists": ["Radojka Šverko", "Marko Škugor", "Klasja Modrušan", "Riječki klavirski trio"]
                }
            ]
        },
        {
            "date": "09.08.2017",
            "title": "Koncert sakralne glazbe",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Koncert br. 1 u d molu za čembalo i gudače BWV 1052",
                    "author": "J. S. Bach",
                    "artists": ["Papandopulo kvartet", "Linda Mravunac-Fabijanić"]
                },
                {
                    "name": "Quatuor pour saxophones",
                    "author": "Pierre-Max Dubois",
                    "artists": ["Papandopulo kvartet", "Linda Mravunac-Fabijanić"]
                },
                {
                    "name": "Šest krokija",
                    "author": "Boris Papandopulo",
                    "artists": ["Papandopulo kvartet", "Linda Mravunac-Fabijanić"]
                },
                {
                    "name": "Patchwork",
                    "author": "Philippe Geiss",
                    "artists": ["Papandopulo kvartet", "Linda Mravunac-Fabijanić"]
                },
                {
                    "name": "Melankolija gegen rakija",
                    "author": "M. Ferek-Petrić",
                    "artists": ["Papandopulo kvartet", "Linda Mravunac-Fabijanić"]
                }
            ]
        }
    ],
    "2018": [
        {
            "date": "06.08.2018.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "06.08.2018.",
            "title": "Papandopulijana mladih umjetnika",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Tri dijaloga za flautu i gitaru ",
                    "author": "Boris Papandopulo",
                    "artists": ["Ana Batinica", "Benjamin Čaušević"]
                },
                {
                    "name": "Quatre Piéces Brèves",
                    "author": "Frank Martin",
                    "artists": ["Ana Batinica", "Benjamin Čaušević"]
                },
                {
                    "name": "Romanian Folk Dances za flautu I gitaru",
                    "author": "Bela Bartok",
                    "artists": ["Ana Batinica", "Benjamin Čaušević"]
                }
            ]
        },
        {
            "date": "07.08.2018.",
            "title": "Svečani koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Himna Republike Hrvatske",
                    "author": "Josip Runjanin",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“", "Klapa „More“"]
                },
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Dalmatinska pjesma",
                    "author": "Boris Papandopulo",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Concertino in modo Antico za dvije violine, violoncello I klavir op. 56",
                    "author": "Boris Papandopulo",
                    "artists": ["Aljoša Jurinić", "Hrvatski gudački kvartet"]
                },
                {
                    "name": "Klavirski kvintet br. 2 op. 81 u A duru",
                    "author": "Antonin Dvorak",
                    "artists": ["Aljoša Jurinić", "Hrvatski gudački kvartet"]
                },
                {
                    "name": "Ma ružice rumena, Ostav se više mora",
                    "author": "Boris Papandopulo",
                    "artists": ["Đani Stipaničev", "Klapa More-Šibenik"]
                },
                {
                    "name": "Glazbeni kolaž",
                    "author": "",
                    "artists": ["Đani Stipaničev", "Klapa More-Šibenik", "Aljoša Jurinić", "Riječki klavirski trio"]
                }

            ]
        },
        {
            "date": "09.08.2018",
            "title": "Koncert sakralne glazbe",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Es ist vollbracht ",
                    "author": "J. S. Bach",
                    "artists": ["Danijel Gašparović", "Sonja Runje"]
                },
                {
                    "name": "Cujus animam gentem",
                    "author": "G. B. Pergolesi",
                    "artists": ["Tanja Ruždjak", "Danijel Gašparović"]
                },
                {
                    "name": "Non t'amo piu",
                    "author": "F. P. Tosti",
                    "artists": ["Danijel Gašparović", "Marin Čargo"]
                },
                {
                    "name": "Iz Čakavske suite; Za sinom",
                    "author": "Boris Papandopulo",
                    "artists": ["Danijel Gašparović", "Sonja Runje"]
                },
                {
                    "name": "Iz Čakavske suite; Zapuščena",
                    "author": "Boris Papandopulo",
                    "artists": ["Tanja Ruždjak", "Danijel Gašparović"]
                },
                {
                    "name": "Kolo mažurane",
                    "author": "Boris Papandopulo",
                    "artists": ["Danijel Gašparović", "Marin Čargo"]
                },
                {
                    "name": "Parto, ma tu ben mio",
                    "author": "W. A. Mozart",
                    "artists": ["Danijel Gašparović", "Sonja Runje"]
                },
                {
                    "name": "Ave Maria, arija Desdemone iz opere Otello",
                    "author": "Giuseppe Verdi",
                    "artists": ["Tanja Ruždjak", "Danijel Gašparović"]
                },
                {
                    "name": "Duet cvijeća (Lakme)",
                    "author": "L. Delibes",
                    "artists": ["Tanja Ruždjak", "Danijel Gašparović", "Sonja Runje"]
                },
                {
                    "name": "Soave sia il vento, tercet iz opere Cosi fan Tutte",
                    "author": "W. A. Mozart",
                    "artists": ["Tanja Ruždjak", "Danijel Gašparović", "Sonja Runje", "Marin Čargo "]
                },
                {
                    "name": "Tercet iz opere N. Š. Zrinski",
                    "author": "Ivan pl. Zajc",
                    "artists": ["Tanja Ruždjak", "Danijel Gašparović", "Sonja Runje", "Marin Čargo "]
                }
            ]
        }
    ],
    "2019": [
        {
            "date": "06.08.2019.",
            "title": "Svečani koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Himna Republike Hrvatske",
                    "author": "Josip Runjanin",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“", "Klapa „More“"]
                },
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Marijo, Marijo",
                    "author": "Boris Papandopulo",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Divertimento u F-duru, K138",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Zagrebački solisti"]
                },
                {
                    "name": "Koncert za ksilofon i gudače",
                    "author": "John Williams",
                    "artists": ["Zagrebački solisti", "Filip Merčep"]
                },
                {
                    "name": "Pintarichiana, suita za gudače",
                    "author": "Boris Papandopulo",
                    "artists": ["Zagrebački solisti"]
                },
                {
                    "name": "Glazbeni kolaž",
                    "author": "",
                    "artists": ["Jacques Houdek", "Antun Stašić", "Mato Matošević"]
                }
            ]
        },
        {
            "date": "07.08.2019.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "07.08.2019.",
            "title": "Papandopulijana mladih umjetnika ",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "10 x 1, deset muzičkih impresija za klavir solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Elizabeta Adžaga"]
                },
                {
                    "name": "Soliloquy op. 61 za flautu solo",
                    "author": "Božidar Kunc",
                    "artists": ["Ivana Vukojević"]
                },
                {
                    "name": "Kameni mozaik, za solo vibrafon",
                    "author": "Anna Ignatowicz-Glinska",
                    "artists": ["Filip Merčep"]
                },
                {
                    "name": "Sonata za flautu i klavir",
                    "author": "Francis Poulenc",
                    "artists": ["Ivana Vukojević", "Elizabeta Adžaga"]
                }
            ]
        },
        {
            "date": "09.08.2019",
            "title": "Koncert sakralne glazbe",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Adagio, obr. za obou i orgulje",
                    "author": "Tomaso Albinoni",
                    "artists": ["Dario Golčić", "Josipa Leko"]
                },
                {
                    "name": "Monolog za violinu solo ",
                    "author": "Boris Papandopulo",
                    "artists": ["Petromila Marica Jakas"]
                },
                {
                    "name": "Passacaglia za orgulje",
                    "author": "Boris Papandopulo",
                    "artists": ["Josipa Leko"]
                },
                {
                    "name": "Tema s varijacijama, op. 150, br. 1",
                    "author": "Josef Rheinberger",
                    "artists": ["Petromila Marica Jakas", "Josipa Leko"]
                },
                {
                    "name": "Romanca",
                    "author": "Max Reger",
                    "artists": ["Petromila Marica Jakas", "Josipa Leko"]
                },
                {
                    "name": "Koncert za obou, violinu i orgulje u d-molu",
                    "author": "Johann Sebastian Bach",
                    "artists": ["Petromila Marica Jakas", "Josipa Leko"]
                }
            ]
        }

    ],
    "2020": [
        {
            "date": "06.08.2020.",
            "title": "Svečani koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Trg Škver",
            "program": [
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Marijo, Marijo",
                    "author": "Boris Papandopulo",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Gudački kvartet op. 18. br. 3. D-duru - 1. Allegro",
                    "author": "Ludwig van Beethoven",
                    "artists": ["Zagrebački kvartet"]
                },
                {
                    "name": "Gudački kvartet br. 3 Narodni - 1. U kolu, 2. Guslarska, 3. Igra – allegro vivace",
                    "author": "Boris Papandopulo",
                    "artists": ["Zagrebački kvartet"]
                },
                {
                    "name": "Koncert za violinu, klavir i gudače u d-molu - 1. Allegro, 2. Adagio, 3. Allegro molto",
                    "author": "Felix Mendelssohn",
                    "artists": ["Danae Papamattheou – Matschke", "Uwe Matschke", "Zagrebački kvartet", "Danijel Radanović"]
                },
                {
                    "name": "Glazbeni kolaž: program za turističku promidžbu Općine Tribunj, Šibensko-kninske županije i RH - Izbor iz operetnih arija",
                    "author": "",
                    "artists": ["Sandra Bagarić", "Darko Domitrović"]
                },
                {
                    "name": "10 X 1 : Deset muzičkih impresija svaka po minutu trajanja za klavir solo - br. 2",
                    "author": "Boris Papandopulo",
                    "artists": ["Sandra Bagarić", "Darko Domitrović"]
                },
                {
                    "name": "Momče mi prođe - popijevka za sopran i klavir",
                    "author": "Boris Papandopulo",
                    "artists": ["Sandra Bagarić", "Darko Domitrović"]
                }
            ]
        },
        {
            "date": "07.08.2020.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "07.08.2020.",
            "title": "Papandopulijana mladih umjetnika ",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Violinska sonata br. 7. u C-molu, opus 30 br. 2 - 1. Allegro con brio,  2. Adagio cantabile, 3. Scherzo. Allegro, 4. Finale: Allegro-Presto",
                    "author": "Ludwig van Beethoven",
                    "artists": ["Danae Papamattheou-Matschke"]
                },
                {
                    "name": "Sonata za violinu i glasovir op. 19 - 1.Allegro con brio, 2. Scherzo, 3. Largo (Sostenuto adagio), 4. Allegro moderato",
                    "author": "Boris Papandopulo",
                    "artists": ["Danae Papamattheou-Matschke", "Uwe Matschke"]
                }
            ]
        },
        {
            "date": "09.08.2020.",
            "title": "Papandopulijana mladih umjetnika – u suradnji s Orguljaškom ljetnom školom ORGANUM Šibenik",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "",
                    "author": "",
                    "artists": ["Tomislav Krobot, orgulje"]
                }
            ]
        },

        // ENGLESKI
        {
            "date": "06.08.2020.",
            "title": "Festival Opening Concert",
            "starting_time": "21:00",
            "location": "Square Škver",
            "language": "en",
            "program": [
                {
                    "name": "Along the coast, along the Adriatic Sea, Tribunj place is called",
                    "author": "",
                    "artists": ["Women's singing group „Saint Nicholas“"]
                },
                {
                    "name": "Mary, Mary",
                    "author": "Boris Papandopulo",
                    "artists": ["Women's singing group „Saint Nicholas“"]
                },
                {
                    "name": "String Quartet op. 18. Nr. 3 in D-Major” - 1. Allegro",
                    "author": "Ludwig van Beethoven",
                    "artists": ["Zagreb Quartet"]
                },
                {
                    "name": "Strig Quartet Nr. 3 – Folk  1. In the circle – with brio, 2. Fiddle – moderato assai, 3. Game – allegro vivace",
                    "author": "Boris Papandopulo",
                    "artists": ["Zagreb Quartet"]
                },
                {
                    "name": "Concerto for Piano, Violin and Strings in D minor, MWV O4 - 1. Allegro, 2. Adagio, 3. Allegro molto",
                    "author": "Felix Mendelssohn",
                    "artists": ["Danae Papamattheou – Matschke", "Uwe Matschke", "Zagreb Quartet", "Danijel Radanović"]
                },
                {
                    "name": "Music collage for the promotion of the Municipality of Tribunj, Šibenik-Knin County and the Republic of Croatia: Selection from Operetta Arias",
                    "author": "",
                    "artists": ["Sandra Bagarić", "Darko Domitrović"]
                },
                {
                    "name": "10 x 1 : Ten musical impressions each lasting one minute for solo piano - Nr. 2",
                    "author": "Boris Papandopulo",
                    "artists": ["Sandra Bagarić", "Darko Domitrović"]
                },
                {
                    "name": "My boy passes – Song for soprano and piano",
                    "author": "Boris Papandopulo",
                    "artists": ["Sandra Bagarić", "Darko Domitrović"]
                }
            ]
        },
        {
            "date": "07.08.2020.",
            "title": "Exhibition opening „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "language": "en",
            "location": "Cultural center Tribunj"
        },
        {
            "date": "07.08.2020.",
            "title": "Papandopulijana young artists ",
            "starting_time": "21:00",
            "location": "Cultural center Tribunj",
            "language": "en",
            "program": [
                {
                    "name": "Violin Sonata Nr. 7. in C-minor, Op. 30 Nr. 2 - 1. Allegro con brio,  2. Adagio cantabile, 3. Scherzo. Allegro, 4. Finale: Allegro-Presto",
                    "author": "Ludwig van Beethoven",
                    "artists": ["Danae Papamattheou-Matschke"]
                },
                {
                    "name": "Sonata for Violin and Piano Op. 19 - 1. Allegro con brio, 2. Scherzo, 3. Largo (Sostenuto adagio), 4. Allegro moderato",
                    "author": "Boris Papandopulo",
                    "artists": ["Danae Papamattheou-Matschke", "Uwe Matschke"]
                }
            ]
        },
        {
            "date": "09.08.2020.",
            "title": "Papandopulijana young artists – In cooperation with Organ summer school - “Organum” Šibenik",
            "starting_time": "21:00",
            "location": "Church of the Assumption",
            "language": "en",
            "program": [
                {
                    "name": "",
                    "author": "",
                    "artists": ["Tomislav Krobot, Organ"]
                }
            ]
        }
    ],
    "2021": [
        {
            "date": "06.08.2021.",
            "title": "Svečani koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Tribunj, Gornja Riva - Škver",
            "program": [
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Marijo Marijo",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Women's singing group „Saint Nicholas“", "Zorica Kondža", "Joško Banov"]
                },
                {
                    "name": "Horoskop: Plesna suita za dva glasovira i udaraljke op. 52",
                    "movements": "Predigra, Ovan, Bik, Blizanci, Rak, Lav, Djevica, Vaga, Škorpion, Strijelac, Jarac, Vodenjak, Ribe",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Zrinka Ivančić Cikojević", "Dalibor Cikojević",
                        "Filip Mercep", "Fran Krsto Šercar",
                        "Linda Tarnovski", "Anika Cetina"]
                },
                {
                    "name": "Glazbeni kolaž: Program za turističku promidžbu Općine Tribunj, Šibensko-kninske županije i Republike Hrvatske",
                    "artists": [
                        "Zorica Kondža i prateći ansambl"
                    ]
                }
            ]
        },
        {

            "date": "07.08.2021.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "07.08.2021.",
            "title": "Papandopulijana mladih umjetnika: Predstavljanje mladih orguljaša orguljaške ljetne škole ORGANUM, Šibenik\n",
            "starting_time": "20:30",
            "location": "Crkva Velike Gospe i Kulturni centar Tribunj",
            "program": [
                {
                    "name": "6 morceaux op. 11",
                    "movements": "Barcarolle, Scherzo, Theme russe, Valse, Romance, Glory",
                    "author": "Sergei Rachmaninoff",
                    "artists": [
                        "Elizabeta Adžaga", "Arsen Dalibaltayan"
                    ]
                },
                {
                    "name": "Plesna suita za klavir četveroručno",
                    "movements": "Radničko kolo, Ketuš, Igra, Tetovka",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Elizabeta Adžaga", "Arsen Dalibaltayan"
                    ]
                }
            ]
        },
        {
            "date": "08.08.2021.",
            "title": "Papandopulova glazbena radionica - udaraljke",
            "starting_time": "19:45",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Koncert prof. Filipa Merčepa i polaznika glazbene radionice",
                    "artists": [
                        "Filip Merčep"
                    ]
                }
            ]
        },
        {
            "date": "09.08.2021.",
            "title": "Koncert komorne glazbe",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Sonata u g-molu RV 58",
                    "movements": "Vivace, Alla breve, Largo, Allegro ma non presto",
                    "author": "Antonio Vivaldi",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Allegro giocoso – dva stara dubrovačka plesa",
                    "movements": "Contradanza, Saltarello (Linđo)",
                    "author": "Mario Nardelli",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },

                {
                    "name": "Tri dijaloga",
                    "movements": "",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Duet op. 61 br. 3",
                    "movements": "Cantabile sostenuto, Ecossais-Allegretto ben marcato",
                    "author": "Francesco Molino",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Tulipan",
                    "movements": "",
                    "author": "Goran Listeš",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Sonatina",
                    "movements": "Allegretto grazioso, Tempo di Siciliana, Scherzo-Rondo. Allegretto con spirito",
                    "author": "Mario Castelnuovo-Tedesco",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                }
            ]
        },
        {
            "date": "06.08.2021.",
            "title": "Festival Opening Concert",
            "starting_time": "21:00",
            "language": "en",
            "location": "Square Škver",
            "program": [
                {
                    "name": "Along the coast, along the Adriatic Sea, Tribunj place is called",
                    "author": "",
                    "artists": ["Women's singing group „Saint Nicholas“"]
                },
                {
                    "name": "Marijo, Marijo",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Women's singing group „Saint Nicholas“", "Zorica Kondža", "Joško Banov"]
                },
                {
                    "name": "Horoskop: Dance Suite for Two Pianos and Percussion Op. 52",
                    "movements": "Predigra, Ovan, Bik, Blizanci, Rak, Lav, Djevica, Vaga, Škorpion, Strijelac, Jarac, Vodenjak, Ribe",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Zrinka Ivančić Cikojević", "Dalibor Cikojević",
                        "Filip Mercep", "Fran Krsto Šercar",
                        "Linda Tarnovski", "Anika Cetina"]
                },
                {
                    "name": "Glazbeni kolaž: Program za turističku promidžbu Općine Tribunj, Šibensko-kninske županije i Republike Hrvatske",
                    "artists": [
                        "Zorica Kondža and the accompanying ensemble"
                    ]
                }
            ]
        },
        {
            "date": "07.08.2021.",
            "title": "Exhibition opening „Boris Papandopulo and Tribunj“",
            "starting_time": "20:30",
            "language": "en",
            "location": "Cultural center Tribunj"
        },
        {
            "date": "07.08.2021.",
            "title": "Papandopulijana young artists ",
            "language": "en",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe and Cultural center Tribunj",
            "program": [
                {
                    "name": "6 morceaux op. 11",
                    "movements": "Barcarolle, Scherzo, Theme russe, Valse, Romance, Glory",
                    "author": "Sergei Rachmaninoff",
                    "artists": [
                        "Elizabeta Adžaga", "Arsen Dalibaltayan"
                    ]
                },
                {
                    "name": "Dance suite for piano four hands",
                    "movements": "Radničko kolo, Ketuš, Igra, Tetovka",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Elizabeta Adžaga", "Arsen Dalibaltayan"
                    ]
                }
            ]
        },
        {
            "date": "08.08.2021.",
            "title": "Papandopulo music workshop - percussion",
            "language": "en",
            "starting_time": "19:45",
            "location": "Cultural center Tribunj",
            "program": [
                {
                    "name": "Concert by prof. Filip Merčep and musical workshop attendees",
                    "artists": [
                        "Filip Merčep"
                    ]
                }
            ]
        },
        {
            "date": "09.08.2021.",
            "title": "Concert of chamber music",
            "language": "en",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Sonata in G minor RV 58",
                    "movements": "Vivace, Alla breve, Largo, Allegro ma non presto",
                    "author": "Antonio Vivaldi",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Allegro giocoso - two old Dubrovnik dances",
                    "movements": "Contradanza, Saltarello (Linđo)",
                    "author": "Mario Nardelli",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Tri dijaloga",
                    "movements": "",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Duet op. 61 br. 3",
                    "movements": "Cantabile sostenuto, Ecossais-Allegretto ben marcato",
                    "author": "Francesco Molino",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Tulipan",
                    "movements": "",
                    "author": "Goran Listeš",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                },
                {
                    "name": "Sonatina",
                    "movements": "Allegretto grazioso, Tempo di Siciliana, Scherzo-Rondo. Allegretto con spirito",
                    "author": "Mario Castelnuovo-Tedesco",
                    "artists": [
                        "Renata Penezić", "Darko Petrinjak"
                    ]
                }
            ]
        }
    ],
    "2022": [
        {
            "date": "06.08.2021.",
            "title": "Koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Tribunj, Gornja Riva - Trg Škver",
            "program": [
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Ljeto, iz četiri godišnja doba RV 315",
                    "movements": "II Adagio, III Presto",
                    "author": "Antonio Vivaldi",
                    "artists": ["Mario Korunić"]
                },
                {
                    "name": "Djetelina s četiri lista",
                    "author": "Boris Papandopulo",
                    "artists": [
                        "Klasja Modrušan", "Christoph Peters"]
                },
                {
                    "name": "Lapadski soneti za bariton i gudače",
                    "movements": "Miholjice, Utjeha",
                    "author": "Boris Papandopulo",
                    "artists": ["Benjamin Šuran"]
                },
                {
                    "name": "Deh vieni non tardar, arija Susanne iz opere Figarov pir",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": [
                        "Klasja Modrušan"]
                },
                {
                    "name": "Non piu andrai, Arija Figara iz opere Figarov pir",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Benjamin Šuran"]
                },
                {
                    "name": "La ci darem la mano, duet iz opere Don Giovanni",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Klasja Modrušan", "Benjamin Šuran"]
                },
                {
                    "name": "All I ask of you, duet iz mjuzikla Fantom u operi",
                    "author": "Andrew Lloyd Webber",
                    "artists": ["Klasja Modrušan", "Benjamin Šuran"]
                },
                {
                    "name": "Marice vrati se",
                    "author": "Anđelko Srbljenović/Ivo Robić, ar: M. Makar",
                    "artists": ["Klasja Modrušan", "Benjamin Šuran"]
                },
                {
                    "name": "Hvala ti srce",
                    "author": "Živan Cvitković, ar: S. Leopold i M. Korunić",
                    "artists": ["Klasja Modrušan", "Benjamin Šuran"]
                },
                {
                    "name": "Back to the roots",
                    "artists": ["Gitanes Blondes"]
                }
            ]
        },
        {

            "date": "07.08.2021.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "07.08.2021.",
            "title": "Papandopulijana mladih umjetnika: Koncert polaznika “Glazbena radionica Boris Papandopulo – marimba”",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Radionica",
                    "artists": ["prof. Katarzyna Mycka (Njemačka)", "prof. Filip Merčep"]
                }
            ]
        },
        {
            "date": "09.08.2021.",
            "title": "Koncert sakralne glazbe",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Iz glagolajškog misala pučke tribunjske mise: Svet",
                    "artists": ["Župni zbor Župe Velike Gospe – Tribunj, Voditeljica: Cvita Gašpić"]
                },
                {
                    "name": "Poljička pučka misa",
                    "author": "Boris Papandopulo",
                    "artists": ["Mješoviti pjevački zbor: Camerata Vocale Split", "Dirigentica: Sara Dodig Baučić", "Orgulje: Ursa Ljuban"]
                },
                {
                    "name": "Zborski repertoar hrvatskih i europkih skladatelja",
                    "author": "I. Lukačić, H Scutz, J. Vrhovski, V. Sunko, F. Lučić, S. Rahmanjinov",
                    "artists": ["Mješoviti pjevački zbor: Camerata Vocale Split", "Dirigentica: Sara Dodig Baučić"]
                },
            ]
        },
    ],
    "2023": [
        {
            "date": "13.08.2022.",
            "title": "Koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Gornja riva - Trg škver",
            "program": [
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Tri kratka komada",
                    "author": "Jacques Ibert",
                    "artists": ["Riječki puhački kvintet"],
                    "movements": "I. Allegro, II. Andante, III. Assez Lent"
                },
                {
                    "name": "Mala suita za puhački kvintet",
                    "author": "Boris Papandopulo",
                    "artists": ["Riječki puhački kvintet"],
                    "movements": "I. Pastorale, II. Intermezzo, III. Scherzo",
                    "bold": true
                },
                {
                    "name": "Šetnja kralja Renéa (La cheminée du roi René), op. 205",
                    "author": "Darius Milhaud",
                    "artists": ["Riječki puhački kvintet"],
                    "movements": "I. Povorka, II. Jutarnja serenada, III. Žongleri, IV. Sarabanda, V. Viteške borbe na rijeci Arc, VI. Lov u Valabreu, VII. Noćni madrigal"
                },
                {
                    "name": "Pet svjetovnih i sakralnih plesova (Cinq Danses profanes et sacrées)",
                    "author": "Henri Tomasi",
                    "artists": ["Riječki puhački kvintet"],
                    "movements": "I. Seoski ples. Allegretto, II. Svjetovni ples. Scherzando, III. Sakralni ples. Lent, IV. Svadbeni ples. Scherzando, V. Ratnički ples. Sauvagement frénétique"
                },
                {
                    "name": "Glazbeni kolaž - program za promociju Općine Tribunj, Šibensko-kninske županije i Republike Hrvatske",
                    "author": "",
                    "artists": ["Đani Stipaničev", "Klapa Ventula", "Zagrebački tamburaški kvartet i Krešimir Španić (berde)"],
                    "movements": ""
                }
            ]
        },
        {
            "date": "14.08.2022.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "14.08.2022.",
            "title": "Papandopulijana mladih umjetnika",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Orbital",
                    "author": "Bruno Vlahek",
                    "artists": ["Zagrebački tamburaški kvartet"]
                },
                {
                    "name": "Panonski ples br. 2",
                    "author": "Tihomir Ranogajec",
                    "artists": ["Zagrebački tamburaški kvartet"]
                },
                {
                    "name": "Divertimento u D-duru",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Zagrebački tamburaški kvartet"],
                    "movements": "I. Allegro, II. Andante, III. Presto"
                },
                {
                    "name": "Contradanza",
                    "author": "Boris Papandopulo",
                    "artists": ["Zagrebački tamburaški kvartet"],
                    "bold": true
                },
                {
                    "name": "Musettura",
                    "author": "Jurica Hrenić",
                    "artists": ["Zagrebački tamburaški kvartet"]
                },
                {
                    "name": "Folklorne kasacije",
                    "author": "Miroslav Miletić",
                    "artists": ["Zagrebački tamburaški kvartet"]
                }
            ]
        },
        {
            "date": "15.08.2022.",
            "title": "Hrvatski Mozart i Amadeus Mozart u Tribunju",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Sonata za čembalo, flautu i čelo u C-duru, KV 14",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Trio Ripos"],
                    "movements": "I. Allegro, II. Allegro"
                },
                {
                    "name": "Suita za čembalo solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Franjo Bilić, čembalo"],
                    "movements": "Meditacija",
                    "bold": true
                },
                {
                    "name": "Razgovor ugodni za flautu i čembalo",
                    "author": "Boris Papandopulo",
                    "artists": ["Marijana Bačelić, flauta i Franjo Bilić, čembalo"],
                    "movements": "I. Allegro con brio, II. Andante sostenuto, III. Allegretto scherzando, IV. Allegro con brio",
                    "bold": true
                },
                {
                    "name": "Rhapsody za violončelo solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Tonka Javorović, violončelo"],
                    "bold": true
                },
                {
                    "name": "Sonata za čembalo, flautu i čelo u G-duru, KV 11",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Trio Ripos"],
                    "movements": "I. Andante, II. Allegro, III. Menuetto, IV. Allegro da capo"
                }
            ]
        },

        // ENGLESKI
        {
            "date": "13.08.2022.",
            "title": "Festival Opening Concert",
            "starting_time": "21:00",
            "location": "Square Škver",
            "language": "en",
            "program": [
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Tri kratka komada",
                    "author": "Jacques Ibert",
                    "artists": ["Riječki puhački kvintet"],
                    "movements": "I. Allegro, II. Andante, III. Assez Lent"
                },
                {
                    "name": "Mala suita za puhački kvintet",
                    "author": "Boris Papandopulo",
                    "artists": ["Riječki puhački kvintet"],
                    "movements": "I. Pastorale, II. Intermezzo, III. Scherzo",
                    "bold": true
                },
                {
                    "name": "Šetnja kralja Renéa (La cheminée du roi René), op. 205",
                    "author": "Darius Milhaud",
                    "artists": ["Riječki puhački kvintet"],
                    "movements": "I. Povorka, II. Jutarnja serenada, III. Žongleri, IV. Sarabanda, V. Viteške borbe na rijeci Arc, VI. Lov u Valabreu, VII. Noćni madrigal"
                },
                {
                    "name": "Pet svjetovnih i sakralnih plesova (Cinq Danses profanes et sacrées)",
                    "author": "Henri Tomasi",
                    "artists": ["Riječki puhački kvintet"],
                    "movements": "I. Seoski ples. Allegretto, II. Svjetovni ples. Scherzando, III. Sakralni ples. Lent, IV. Svadbeni ples. Scherzando, V. Ratnički ples. Sauvagement frénétique"
                },
                {
                    "name": "Glazbeni kolaž - program za promociju Općine Tribunj, Šibensko-kninske županije i Republike Hrvatske",
                    "author": "",
                    "artists": ["Đani Stipaničev", "Klapa Ventula", "Zagrebački tamburaški kvartet i Krešimir Španić (berde)"],
                    "movements": ""
                }
            ]
        },
        {
            "date": "14.08.2022.",
            "title": "Exhibition opening „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "language": "en",
            "location": "Cultural center Tribunj"
        },
        {
            "date": "14.08.2022.",
            "title": "Papandopulijana young artists ",
            "starting_time": "21:00",
            "location": "Cultural center Tribunj",
            "language": "en",
            "program": [
                {
                    "name": "Orbital",
                    "author": "Bruno Vlahek",
                    "artists": ["Zagrebački tamburaški kvartet"]
                },
                {
                    "name": "Panonski ples br. 2",
                    "author": "Tihomir Ranogajec",
                    "artists": ["Zagrebački tamburaški kvartet"]
                },
                {
                    "name": "Divertimento u D-duru",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Zagrebački tamburaški kvartet"],
                    "movements": "I. Allegro, II. Andante, III. Presto"
                },
                {
                    "name": "Contradanza",
                    "author": "Boris Papandopulo",
                    "artists": ["Zagrebački tamburaški kvartet"],
                    "bold": true
                },
                {
                    "name": "Musettura",
                    "author": "Jurica Hrenić",
                    "artists": ["Zagrebački tamburaški kvartet"]
                },
                {
                    "name": "Folklorne kasacije",
                    "author": "Miroslav Miletić",
                    "artists": ["Zagrebački tamburaški kvartet"]
                }
            ]
        },
        {
            "date": "15.08.2022.",
            "title": "Croatian Mozart and Amadeus Mozart in Tribunj",
            "starting_time": "21:00",
            "location": "Church of the Assumption",
            "program": [
                {
                    "name": "Sonata za čembalo, flautu i čelo u C-duru, KV 14",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Trio Ripos"],
                    "movements": "I. Allegro, II. Allegro"
                },
                {
                    "name": "Suita za čembalo solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Franjo Bilić, čembalo"],
                    "movements": "Meditacija",
                    "bold": true
                },
                {
                    "name": "Razgovor ugodni za flautu i čembalo",
                    "author": "Boris Papandopulo",
                    "artists": ["Marijana Bačelić, flauta i Franjo Bilić, čembalo"],
                    "movements": "I. Allegro con brio, II. Andante sostenuto, III. Allegretto scherzando, IV. Allegro con brio",
                    "bold": true
                },
                {
                    "name": "Rhapsody za violončelo solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Tonka Javorović, violončelo"],
                    "bold": true
                },
                {
                    "name": "Sonata za čembalo, flautu i čelo u G-duru, KV 11",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Trio Ripos"],
                    "movements": "I. Andante, II. Allegro, III. Menuetto, IV. Allegro da capo"
                },
            ],
            "language": "en"
        }
    ],
    "2024": [
        {
            "date": "13.08.2024.",
            "title": "Koncert otvorenja festivala",
            "starting_time": "21:00",
            "location": "Gornja riva - Trg škver",
            "program": [
                {
                    "name": "Uz obalu, uz Jadransko more, Tribunj misto ovako se zove",
                    "author": "",
                    "artists": ["Ženska pjevačka skupina „Sveti Nikola“"]
                },
                {
                    "name": "Simfonija br. 4 (obr. Y. Sadovyy)",
                    "author": "Luka Sorkočević",
                    "artists": ["Kalamos kvintet"],
                    "movements": "I. Allegro non troppo, II. Andante non troppo, III. Allegro"
                },
                {
                    "name": "Adagio",
                    "author": "Antun Sorkočević / Boris Papandopulo",
                    "artists": ["Kalamos kvintet"]
                },
                {
                    "name": "Notturno (obr. Y. Sadovyy)",
                    "author": "Blagoje Bersa",
                    "artists": ["Kalamos kvintet"]
                },
                {
                    "name": "Varijacije na popularnu božićnu temu",
                    "author": "Boris Papandopulo",
                    "artists": ["Kalamos kvintet"]
                },
                {
                    "name": "Fragmenti (praizvedba)",
                    "author": "David Batinić",
                    "artists": ["Kalamos kvintet"]
                },
                {
                    "name": "Nadsviravanje",
                    "author": "Boris Papandopulo",
                    "artists": ["Kalamos kvintet"]
                },
                {
                    "name": "Ride for five",
                    "author": "Bruno Vlahek",
                    "artists": ["Kalamos kvintet"],
                    "movements": "I. Maestoso – Allegro deciso, II. Andante ipnotico, III. Vivacissimo, molto capriccioso"
                },
                {
                    "name": "Glazbeno-scenski prikaz tribunjske legende: “Kako je Ćušo spasio Tribunj”, program za promociju Općine Tribunj, Šibensko-kninske županije i Republike Hrvatske",
                    "author": "Hrvoje Zalar (redatelj/autor teksta), Šimun Matišić (autor glazbe)",
                    "artists": ["glumci: Hrvoje Zalar, Anja Đurinović, Andrej Kopćok", "glazbenici: Filip Merčep, Elizabeta Adžaga Merčep, Šimun Matišić"]
                }
            ]
        },
        {
            "date": "14.08.2024.",
            "title": "Otvorenje izložbe „Boris & Zdenka Papandopulo i Tribunj“",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "14.08.2024.",
            "title": "Papandopulijana mladih umjetnika",
            "starting_time": "21:00",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Andante iz sonate za violončelo i klavir u g-molu, op. 19",
                    "author": "Sergej Rahmanjinov",
                    "artists": ["Tomislav Damjanović, klavir", "Martin Krpan, violina"]
                },
                {
                    "name": "Monolog za violinu solo",
                    "author": "Boris Papandopulo",
                    "artists": ["Martin Krpan, violina"]
                },
                {
                    "name": "7. studija za klavir",
                    "author": "Boris Papandopulo",
                    "artists": ["Tomislav Damjanović, klavir"]
                },
                {
                    "name": "Meditacija",
                    "author": "Boris Papandopulo",
                    "artists": ["Tomislav Damjanović, klavir", "Martin Krpan, violina"]
                },
                {
                    "name": "Sonata za klavir i violinu u G-duru, KV 379",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Tomislav Damjanović, klavir", "Martin Krpan, violina"],
                    "movements": "I. Adagio – Allegro, II. Andantino cantabile. Thema con variazioni"
                },
                {
                    "name": "Sonata za violinu i klavir u g-molu, L. 140",
                    "author": "Claude Debussy",
                    "artists": ["Tomislav Damjanović, klavir", "Martin Krpan, violina"],
                    "movements": "I. Allegro vivo, II. Intermède: Fantasque et léger, III. Finale: Très animé"
                }
            ]
        },
        {
            "date": "15.08.2024.",
            "title": "Trio Evocación u Tribunju",
            "starting_time": "21:00",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Diptih za tri gitare",
                    "author": "Frano Parać",
                    "artists": ["Trio Evocación"]
                },
                {
                    "name": "Evocación, Triana i Aragon (obr. Darko Petrinjak)",
                    "author": "Isaac Albéniz",
                    "artists": ["Trio Evocación"]
                },
                {
                    "name": "April is the cruellest month",
                    "author": "Marko Ruždjak",
                    "artists": ["Trio Evocación"]
                },
                {
                    "name": "Tri igre (za gitaru solo)",
                    "author": "Boris Papandopulo",
                    "artists": ["Trio Evocación"],
                    "movements": "I. Allegretto vivace, II. Moderato, III. Allegro comodo"
                },
                {
                    "name": "Triptih",
                    "author": "Krešimir Klarić",
                    "artists": ["Trio Evocación"]
                },
                {
                    "name": "Ples susjeda (Seguidillas) i Ples mlinara (Farruca) iz baleta Trorogi šešir (obr. Darko Petrinjak)",
                    "author": "Manuel de Falla",
                    "artists": ["Trio Evocación"]
                }
            ]
        },


        // ENGLESKI
        {
            "date": "13.08.2024.",
            "title": "Opening Concert of the Festival",
            "starting_time": "21:00",
            "location": "Gornja riva - Trg škver",
            "language": "en",
            "program": [
                {
                    "name": "Along the coast, along the Adriatic Sea, Tribunj is called like this",
                    "author": "",
                    "artists": ["Women's Singing Group 'Saint Nicholas'"]
                },
                {
                    "name": "Symphony No. 4 (arr. Y. Sadovyy)",
                    "author": "Luka Sorkočević",
                    "artists": ["Kalamos Quintet"],
                    "movements": "I. Allegro non troppo, II. Andante non troppo, III. Allegro"
                },
                {
                    "name": "Adagio",
                    "author": "Antun Sorkočević / Boris Papandopulo",
                    "artists": ["Kalamos Quintet"]
                },
                {
                    "name": "Notturno (arr. Y. Sadovyy)",
                    "author": "Blagoje Bersa",
                    "artists": ["Kalamos Quintet"]
                },
                {
                    "name": "Variations on a Popular Christmas Theme",
                    "author": "Boris Papandopulo",
                    "artists": ["Kalamos Quintet"]
                },
                {
                    "name": "Fragments (world premiere)",
                    "author": "David Batinić",
                    "artists": ["Kalamos Quintet"]
                },
                {
                    "name": "Improvisation",
                    "author": "Boris Papandopulo",
                    "artists": ["Kalamos Quintet"]
                },
                {
                    "name": "Ride for Five",
                    "author": "Bruno Vlahek",
                    "artists": ["Kalamos Quintet"],
                    "movements": "I. Maestoso – Allegro deciso, II. Andante ipnotico, III. Vivacissimo, molto capriccioso"
                },
                {
                    "name": "Musical-Scenic Presentation of the Tribunj Legend: 'How Ćušo Saved Tribunj', program for the promotion of the Municipality of Tribunj, Šibenik-Knin County and the Republic of Croatia",
                    "author": "Hrvoje Zalar (director/author of the text), Šimun Matišić (composer)",
                    "artists": ["actors: Hrvoje Zalar, Anja Đurinović, Andrej Kopćok", "musicians: Filip Merčep, Elizabeta Adžaga Merčep, Šimun Matišić"]
                }
            ]
        },
        {
            "date": "14.08.2024.",
            "title": "Exhibition Opening: 'Boris & Zdenka Papandopulo and Tribunj'",
            "language": "en",
            "starting_time": "20:30",
            "location": "Kulturni centar Tribunj"
        },
        {
            "date": "14.08.2024.",
            "title": "Papandopulijana of Young Artists",
            "starting_time": "21:00",
            "language": "en",
            "location": "Kulturni centar Tribunj",
            "program": [
                {
                    "name": "Andante from the Cello and Piano Sonata in G minor, op. 19",
                    "author": "Sergei Rachmaninoff",
                    "artists": ["Tomislav Damjanović, piano", "Martin Krpan, violin"]
                },
                {
                    "name": "Monologue for Solo Violin",
                    "author": "Boris Papandopulo",
                    "artists": ["Martin Krpan, violin"]
                },
                {
                    "name": "7th Piano Study",
                    "author": "Boris Papandopulo",
                    "artists": ["Tomislav Damjanović, piano"]
                },
                {
                    "name": "Meditation",
                    "author": "Boris Papandopulo",
                    "artists": ["Tomislav Damjanović, piano", "Martin Krpan, violin"]
                },
                {
                    "name": "Sonata for Piano and Violin in G major, KV 379",
                    "author": "Wolfgang Amadeus Mozart",
                    "artists": ["Tomislav Damjanović, piano", "Martin Krpan, violin"],
                    "movements": "I. Adagio – Allegro, II. Andantino cantabile. Thema con variazioni"
                },
                {
                    "name": "Sonata for Violin and Piano in G minor, L. 140",
                    "author": "Claude Debussy",
                    "artists": ["Tomislav Damjanović, piano", "Martin Krpan, violin"],
                    "movements": "I. Allegro vivo, II. Intermède: Fantasque et léger, III. Finale: Très animé"
                }
            ]
        },
        {
            "date": "15.08.2024.",
            "title": "Trio Evocación in Tribunj",
            "starting_time": "21:00",
            "language": "en",
            "location": "Crkva Velike Gospe",
            "program": [
                {
                    "name": "Diptych for Three Guitars",
                    "author": "Frano Parać",
                    "artists": ["Trio Evocación"]
                },
                {
                    "name": "Evocación, Triana and Aragon (arr. Darko Petrinjak)",
                    "author": "Isaac Albéniz",
                    "artists": ["Trio Evocación"]
                },
                {
                    "name": "April is the Cruellest Month",
                    "author": "Marko Ruždjak",
                    "artists": ["Trio Evocación"]
                },
                {
                    "name": "Three Games (for solo guitar)",
                    "author": "Boris Papandopulo",
                    "artists": ["Trio Evocación"],
                    "movements": "I. Allegretto vivace, II. Moderato, III. Allegro comodo"
                },
                {
                    "name": "Triptych",
                    "author": "Krešimir Klarić",
                    "artists": ["Trio Evocación"]
                },
                {
                    "name": "Neighbors' Dance (Seguidillas) and Miller's Dance (Farruca) from the ballet The Three-Cornered Hat (arr. Darko Petrinjak)",
                    "author": "Manuel de Falla",
                    "artists": ["Trio Evocación"]
                }
            ]
        }
    ],
};
